import { Input, InputNumber, Modal, Select, Spin } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import ButtonDefault, { ButtonVariants } from "../../shared/basic/button";
import { useUsers } from "../../../hooks/useUsers";
import { UserData, userRolesEnumMapping } from "../../../utils/interfaces/user";
import { ShouldRender } from "../../shared/basic/ShouldRender";

interface Props {
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  editId?: number | undefined;
}

const UserModal: React.FC<Props> = ({
  isModalOpen,
  handleOk,
  handleCancel,
  editId,
}) => {
  const {
    roles,
    getAllRoles,
    createUser,
    getUser,
    user,
    updateUser,
    loadingEdit,
  } = useUsers();

  useEffect(() => {
    getAllRoles();
  }, []);
  const formattedObject = useMemo(() => {
    return {
      name: user?.name || "",
      email: user?.email || "",
      gender: user?.gender || "",
      password: "",
      phoneNumber: user?.phone || "",
      role: user?.role_id,
    };
  }, [user]);

  const [formData, setFormData] = useState<UserData>({
    name: "",
    email: "",
    gender: "",
    password: "",
    phoneNumber: "",
    role: undefined,
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    gender: "",
    password: "",
    phoneNumber: "",
    role: "",
  });

  useEffect(() => {
    if (editId) {
      getUser(editId);
    }
  }, [editId]);

  useEffect(() => {
    if (editId) {
      setFormData(formattedObject);
    }
  }, [user]);

  const selectOptions = useMemo(() => {
    return roles?.map((item) => ({
      label: userRolesEnumMapping[item.name] || item.name,
      value: item.id,
    }));
  }, [roles]);

  const isValidEmail = (email: string) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return emailRegex.test(email);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    // Validation
    const validationErrors = {
      name: "",
      email: "",
      gender: "",
      password: "",
      phoneNumber: "",
      role: "",
    };

    if (!formData.name) {
      validationErrors.name = "Name is required";
    }

    if (!formData.email) {
      validationErrors.email = "Email is required";
    } else if (!isValidEmail(formData.email)) {
      validationErrors.email = "Invalid email";
    }

    if (!formData.gender) {
      validationErrors.gender = "Gender is required";
    }

    if (!formData.phoneNumber) {
      validationErrors.phoneNumber = "Phone number is required";
    } else if (formData.phoneNumber.length !== 10) {
      validationErrors.phoneNumber = "Phone number should have 10 digits";
    }

    if (!formData.phoneNumber) {
      validationErrors.phoneNumber = "Phone number is required";
    }

    if (formData.role === undefined) {
      validationErrors.role = "Role is required";
    }

    setErrors(validationErrors);

    if (Object.values(validationErrors).every((error) => !error)) {
      if (editId) {
        try {
          updateUser(formData, editId);
        } finally {
          handleOk();
          handleClose();
        }
      } else {
        try {
          createUser(formData);
        } finally {
          handleOk();
          handleClose();
        }
      }
    }
  };

  const handleClose = () => {
    setFormData({
      name: "",
      email: "",
      gender: "",
      password: "",
      phoneNumber: "",
      role: undefined,
    });
    setErrors({
      name: "",
      email: "",
      gender: "",
      password: "",
      phoneNumber: "",
      role: "",
    });
    handleCancel();
  };
  const selectOptionsGender = useMemo(() => {
    return [
      { label: "Male", value: "male" },
      { label: "Female", value: "female" },
      { label: "Other", value: "other" },
    ];
  }, []);
  return (
    <Modal
      title={editId ? "Edit User" : "Add new user"}
      open={isModalOpen}
      onOk={handleSubmit}
      onCancel={handleClose}
      footer={
        <div className="flex gap-x-3 justify-end mt-4">
          <ButtonDefault
            size={1}
            variant={ButtonVariants.WHITE}
            onClick={handleClose}
          >
            <span className="px-2 py-1">Cancel</span>
          </ButtonDefault>
          <ButtonDefault
            size={1}
            variant={ButtonVariants.PRIMARY}
            onClick={handleSubmit} // Handle form submission
          >
            <span className="px-6 py-1">{editId ? "Edit" : "Create"}</span>
          </ButtonDefault>
        </div>
      }
    >
      <form onSubmit={handleSubmit}>
        {loadingEdit ? (
          <div
            className="h-40 flex items-center justify-center"
            style={{ textAlign: "center" }}
          >
            <Spin size="large" />
          </div>
        ) : (
          <div className="flex flex-col gap-y-2 border-t pt-4">
            <div className="flex flex-col gap-y-1.5">
              <span className="text-sm font-medium">Name</span>
              <Input
                name="name"
                value={formData.name}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                size="large"
                className=" "
              />
              {errors.name && (
                <span className="text-red-500">{errors.name}</span>
              )}
            </div>
            <ShouldRender check={!editId}>
              <div className="flex flex-col gap-y-1.5">
                <span className="text-sm font-medium">Email Address</span>
                <Input
                  name="email"
                  value={formData.email}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                  size="large"
                  className=" "
                />
                {errors.email && (
                  <span className="text-red-500">{errors.email}</span>
                )}
              </div>
            </ShouldRender>

            <div className="flex flex-col gap-y-1.5">
              <span className="text-sm font-medium">Gender</span>
              <Select
                size="large"
                placeholder="Select Gender"
                value={formData.gender}
                onChange={(value) =>
                  setFormData({ ...formData, gender: value })
                }
                style={{ width: "100%" }}
                options={selectOptionsGender}
              />
              {errors.gender && (
                <span className="text-red-500">{errors.gender}</span>
              )}
            </div>
            <div className="flex flex-col gap-y-1.5">
              <span className="text-sm font-medium">Set Password</span>
              <Input.Password
                name="password"
                value={formData.password}
                onChange={(e) =>
                  setFormData({ ...formData, password: e.target.value })
                }
                size="large"
                className=""
              />
              {errors.password && (
                <span className="text-red-500">{errors.password}</span>
              )}
            </div>
            <div className="flex flex-col gap-y-1.5">
              <span className="text-sm font-medium">Phone number (+91)</span>
              <InputNumber
                name="phoneNumber"
                prefix="+91"
                value={formData.phoneNumber}
                onKeyDown={(e) => {
                  if (
                    !(
                      e.key.match(/^[0-9]$/) ||
                      e.key === "Backspace" ||
                      e.key === "Delete"
                    )
                  ) {
                    e.preventDefault();
                  }
                }}
                onChange={(value) =>
                  setFormData({ ...formData, phoneNumber: String(value) })
                }
                size="large"
                controls={false}
                className="w-full"
              />

              {errors.phoneNumber && (
                <span className="text-red-500">{errors.phoneNumber}</span>
              )}
            </div>
            <div className="flex flex-col gap-y-1.5">
              <span className="text-sm font-medium">Role</span>
              <Select
                size="large"
                placeholder="Select role"
                value={formData.role}
                onChange={(value) => setFormData({ ...formData, role: value })}
                style={{ width: "100%" }}
                options={selectOptions}
              />
              {errors.role && (
                <span className="text-red-500">{errors.role}</span>
              )}
            </div>
          </div>
        )}
      </form>
    </Modal>
  );
};

export default UserModal;
