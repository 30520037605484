import React, { ReactNode } from "react";
import { LoginArrow, LoginBG } from "../../assets";

interface Props {
  children?: ReactNode;
}

const AuthLayout: React.FC<Props> = ({ children }) => {
  return (
    <div className="md:bg-[#EAEAEA] z-10 overflow-y-hidden relative">
      <div className="hidden md:block w-full top-10 absolute z-20">
        <div className="w-1/3">
          <div className="w-full flex justify-center items-center ">
            <img src={LoginArrow} alt="LoginBG " className=" w-full" />
          </div>
        </div>
      </div>
      <div className=" hidden md:block w-full bottom-0 absolute z-20">
        <div className="h-1/2 xl:translate-y-20">
          <div className="w-full flex justify-center items-center ">
            <img src={LoginBG} alt="LoginBG " className=" w-full" />
          </div>
        </div>
      </div>
      <span className="z-50">{children}</span>
    </div>
  );
};
export default AuthLayout;
