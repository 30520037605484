import React, { useEffect, useState } from "react";
import { Table, Switch, Avatar, Badge, Modal, Empty } from "antd";
import { useUsers } from "../../hooks/useUsers";
import {
  DataSourceItem,
  userRolesEnumMapping,
} from "../../utils/interfaces/user";
import { IndiaFlag } from "../../assets";
import { ShouldRender } from "../shared/basic/ShouldRender";
import { getCountryName } from "../../hooks/basicFunctions";
import moment from "moment";
import UserModal from "./UserModal";
import { useLocation, useNavigate } from "react-router-dom";
import { ExclamationCircleIcon, PencilAltIcon } from "@heroicons/react/outline";
import ButtonDefault, { ButtonVariants } from "../shared/basic/button";
import { updateStatus } from "../../services/user";
import { notify } from "../shared/basic/notify";
import { letterColors } from "../../utils/data";
import extractErrorMessage from "../shared/basic/formateError";

const AllUser: React.FC = () => {
  const { allUsers, getAllUsers, loading, paginationData } = useUsers();
  const router = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [data, setData] = useState<DataSourceItem[] | undefined>([]);
  const [isModalOpenUser, setIsModalOpenUser] = useState(false);
  const [editId, setEditId] = useState<number | undefined>(undefined);
  const [page, setPage] = useState<number>();
  const [editStatus, setEditStatus] = useState<{
    id: number | null;
    status: number | null;
  }>({
    id: null,
    status: null,
  });

  const handleStatusChange = (key: number, checked: boolean) => {
    if (checked) {
      setEditStatus({ id: key, status: 1 });
    } else {
      setEditStatus({ id: key, status: 0 });
    }

    setIsModalVisible(true);
  };

  const handleModalOk = async () => {
    try {
      const response = await updateStatus(editStatus.id, editStatus.status);
      if (response) {
        getAllUsers();
      }
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      handleModalCancel();
    }
    setIsModalVisible(false);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    getAllUsers(page);
  }, [isModalOpenUser, page]);

  const handleOkUser = () => {
    setIsModalOpenUser(false);
    setEditId(undefined);
  };

  const handleCancelUser = () => {
    setIsModalOpenUser(false);
    setEditId(undefined);
  };

  useEffect(() => {
    if (location?.search === "?isModalOpenUser=true") {
      setIsModalOpenUser(true);
      query.delete("isModalOpenUser");
      router({ search: `?${query.toString()}` });
    }
  }, [location?.search]);

  useEffect(() => {
    setData(allUsers);
  }, [allUsers, loading]);

  const columns = [
    {
      title: (
        <span className="text-xs text-gray-500 flex justify-center">S.N.</span>
      ),
      dataIndex: "id",
      width: 50,
      key: "id",
      render: (text: string) => (
        <div className="border rounded-md flex justify-center w-min px-2 py-1 text-xs">
          {text}
        </div>
      ),
    },
    {
      title: (
        <span className="text-xs text-gray-500 flex justify-center">Name</span>
      ),
      width: 200,
      key: "name",
      render: (record: DataSourceItem) => {
        return (
          <div className="flex gap-x-2 items-center">
            <span className="w-12 h-12 flex items-center">
              <Avatar
                size={40}
                style={{
                  backgroundColor:
                    (letterColors as Record<string, string>)[
                      record?.name?.charAt(0)
                    ] || "#000000",
                }}
              >
                {record?.name?.charAt(0)}
              </Avatar>
            </span>

            <div className="flex flex-col ">
              <span className="font-medium truncate">{record?.name}</span>
              <span className="font-medium italic text-gray-400 text-xs">
                @{userRolesEnumMapping[record?.roles[0]?.name || ""]}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      title: (
        <span className="text-xs text-gray-500 flex justify-center">
          Country
        </span>
      ),
      key: "country_code",
      render: (record: DataSourceItem) => {
        return (
          <div className="flex gap-x-2 items-center justify-center">
            <span className="w-4 h-4">
              <ShouldRender check={record?.country_code === "+91"}>
                <img
                  src={IndiaFlag}
                  alt="Book"
                  className="object-contain h-4 w-full"
                />
              </ShouldRender>
            </span>

            <div className="flex flex-col items-center">
              <span className="font-medium text-gray-500">
                {getCountryName(record?.country_code)}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      title: (
        <span className="text-xs text-gray-500 flex justify-center">
          Status
        </span>
      ),
      dataIndex: "active_status",
      key: "active_status",
      render: (active_status: number) => (
        <div
          className={`${
            active_status === 1
              ? "bg-green-100 text-green-500"
              : "bg-gray-100 text-gray-500"
          } flex gap-x-2 px-2 text-xs font-medium justify-center rounded-full py-1`}
        >
          {" "}
          <Badge color={active_status === 1 ? "green" : "#C0C0C0"} />
          {active_status === 1 ? "Active" : "Inactive"}
        </div>
      ),
    },

    {
      title: (
        <span className="text-xs text-gray-500 flex justify-center whitespace-nowrap">
          Phone Number
        </span>
      ),
      key: "phone",
      render: (record: DataSourceItem) => (
        <div className=" flex justify-center   px-2 py-1 whitespace-nowrap">
          {record?.country_code + " " + record?.phone}
        </div>
      ),
    },
    {
      title: (
        <span className="text-xs text-gray-500 flex justify-center">
          Email Address
        </span>
      ),
      dataIndex: "email",
      key: "email",
      render: (email: string) => (
        <div className=" flex justify-center   ">{email}</div>
      ),
    },

    {
      title: (
        <span className="text-xs text-gray-500 flex justify-center">
          Created At
        </span>
      ),
      dataIndex: "created_at",
      key: "created_at",
      render: (date: string) => (
        <div className=" flex justify-center   ">
          {moment(date).format("MMMM D, YYYY")}
        </div>
      ),
    },

    {
      title: (
        <span className="text-xs text-gray-500 flex justify-center">
          Action
        </span>
      ),
      dataIndex: "active_status",
      key: "Action",
      render: (active_status: number, record: DataSourceItem) => (
        <div className="flex gap-x-4 justify-center">
          <div className="flex gap-x-2 items-center">
            <span className="font-medium text-xs">InActive</span>
            <Switch
              checked={active_status === 1}
              className="bg-gray-200"
              onChange={(checked) => handleStatusChange(record.id, checked)}
            />
            <span className="font-medium text-xs">Active</span>
          </div>

          <PencilAltIcon
            onClick={() => {
              setEditId(record?.id);
              setIsModalOpenUser(true);
            }}
            className="w-6 h-6 hover:cursor-pointer text-gray-500 hover:text-gray-800"
          />
        </div>
      ),
    },
  ];

  const usersWithKeys = data?.map((user) => ({
    ...user,
    key: user.id.toString(),
  }));

  console.log(paginationData, "paginationData");

  return (
    <div className="bg-white rounded-md flex flex-col">
      <span className="px-5 font-medium py-4 text-base flex gap-x-4 items-center">
        All Users{" "}
        <p className="text-xs bg-red-50 text-red-500 px-4 rounded-full py-1">
          {paginationData?.total} users
        </p>
      </span>
      <div className="overflow-auto ">
        <Table
          dataSource={usersWithKeys}
          columns={columns}
          loading={loading}
          pagination={{
            current: page,
            pageSize: paginationData?.per_page || 0,
            total: paginationData?.total || 0,
            onChange: (newPage) => {
              setPage(newPage);
            },
          }}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>No Users</span>}
                className=""
              />
            ),
          }}
        />
      </div>
      <UserModal
        isModalOpen={isModalOpenUser}
        editId={editId}
        handleOk={handleOkUser}
        handleCancel={handleCancelUser}
      />
      <Modal
        open={isModalVisible}
        onOk={handleModalOk}
        closable={false}
        footer={false}
        onCancel={handleModalCancel}
      >
        <div className="flex justify-center pt-4">
          <div className="flex flex-col gap-y-3 justify-center">
            <span className="flex justify-center">
              <ExclamationCircleIcon className="w-12 h-12 text-yellow-400 " />
            </span>
            <span className="text-xl">
              {" "}
              Are you sure you want to change status of this user?
            </span>
          </div>
        </div>

        <div className="flex gap-x-3 justify-center mt-9">
          <ButtonDefault
            size={1}
            variant={ButtonVariants.WHITE}
            onClick={handleModalCancel}
          >
            <span className="px-4 py-1">No</span>
          </ButtonDefault>
          <ButtonDefault
            size={1}
            variant={ButtonVariants.PRIMARY}
            onClick={handleModalOk} // Handle form submission
          >
            <span className="px-4 py-1">Yes</span>
          </ButtonDefault>
        </div>
      </Modal>
    </div>
  );
};

export default AllUser;
