import { toast, ToastContent } from 'react-toastify';

export const notify = (
  message: ToastContent,
  type: 'info' | 'success' | 'warning' | 'error' = 'info'
) => {
  toast.dismiss();

  switch (type) {
    case 'info':
      toast.info(message);
      break;
    case 'success':
      toast.success(message);
      break;
    case 'warning':
      toast.warn(message);
      break;
    case 'error':
      toast.error(message);
      break;
    default:
      toast(message);
      break;
  }
};
