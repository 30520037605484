import { Table } from "antd";
import { ColumnsType } from "antd/es/table";

import React, { useEffect, useMemo, useState } from "react";

import ButtonDefault, { ButtonVariants } from "../../shared/basic/button";
import { Book, Logo } from "../../../assets";
import { PaginationProps } from "../../../hooks/useUsers";
import { getAllCampaigns } from "../../../services/campaigns";
import { notify } from "../../shared/basic/notify";
import { CampaignData } from "../../../utils/interfaces/user";
import moment from "moment";
import { ChannelsImages, bookImagesMap } from "../../../utils/data";
import CreateCampaigns from "../../Campaigns/CreateCampaigns";
import { useLocation, useNavigate } from "react-router-dom";
import UploadModal from "./UploadModal";
import { PencilIcon } from "@heroicons/react/outline";
import ViewLogsModal from "./ViewLogsModal";
import { ShouldRender } from "../../shared/basic/ShouldRender";
import extractErrorMessage from "../../shared/basic/formateError";

interface StatusButtonProps {
  isActive: boolean;
}

export const convertToIndianCommaFormat = (number: number): string => {
  return number.toLocaleString("en-IN");
};

const StatusButton: React.FC<StatusButtonProps> = ({ isActive }) => {
  return (
    <>
      <div
        className={`rounded-full ${
          isActive ? "bg-[#D3F9D8] text-[#25D228]" : "bg-gray-100 text-gray-400"
        } w-1/2 text-center text-xs py-1 font-medium`}
      >
        {isActive ? "Active" : "Inactive"}
      </div>
    </>
  );
};

const MarketingCampaignTable = () => {
  const [loading, setLoading] = useState<boolean>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenLogs, setIsModalOpenLogs] = useState(false);
  const [isModalOpenUpload, setIsModalOpenUpload] = useState(false);
  const [page, setPage] = useState<number>();
  const [data, setData] = useState<CampaignData[]>([]);
  const [paginationData, setPaginationData] = useState<PaginationProps>();
  const location = useLocation();
  const [editId, setEditId] = useState<number>();
  const [campaignId, setCampaignId] = useState<string>();

  const query = new URLSearchParams(location.search);
  const router = useNavigate();

  const handleGetAllCampaigns = async () => {
    try {
      setLoading(true);
      const response = await getAllCampaigns(page as number);

      const { data } = response;

      setData(data?.data);
      setPaginationData({
        current_page: data?.current_page,
        per_page: data?.per_page,
        total: data?.total,
      });
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetAllCampaigns();
  }, [page, isModalOpen, isModalOpenUpload]);

  useEffect(() => {
    if (location?.search === "?isModalOpenAddCampaign=true") {
      setIsModalOpen(true);
      query.delete("isModalOpenAddCampaign");
      router({ search: `?${query.toString()}` });
    }
  }, [location?.search]);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setEditId(undefined);
  };

  const handleOkUpload = () => {
    setIsModalOpenUpload(false);
  };

  const handleCancelUpload = () => {
    setIsModalOpenUpload(false);
    setEditId(undefined);
  };

  const handleCancelLogs = () => {
    setIsModalOpenLogs(false);
    setEditId(undefined);
  };

  const formattedData = useMemo(() => {
    return data?.map((item) => {
      return {
        id: item?.id,
        activityName: item?.campaign_name,
        activityStatus: moment(item.end_date).isSameOrAfter(item.end_date),
        bookName: item?.base_product?.name,
        bookId: item?.base_product?.id,
        distribution: item?.module.name,
        channels: item?.distinct_channel_names || "N/A",
        budget: item?.budget
          ? `₹ ${convertToIndianCommaFormat(Number(item?.budget?.toFixed(2)))}`
          : "N/A",
        totalIncome: item?.channel_campaigns_sum_amount_spent
          ? `₹ ${convertToIndianCommaFormat(
              Number(item?.channel_campaigns_sum_amount_spent?.toFixed(2))
            )}`
          : "N/A",
        startDate: item?.start_date,
        endDate: item?.end_date,
        quantity: item?.channel_campaigns_sum_purchases || "N/A",
        reachData: [
          {
            label: "Reach",
            value: item?.channel_campaigns_sum_impressions
              ? `${convertToIndianCommaFormat(
                  Number(item?.channel_campaigns_sum_impressions?.toFixed(2))
                )}`
              : "N/A",
          },
          {
            label: "Impressions",
            value: item?.channel_campaigns_sum_reach
              ? `${convertToIndianCommaFormat(
                  Number(item?.channel_campaigns_sum_reach?.toFixed(2))
                )}`
              : "N/A",
          },
        ],
        action: item?.meta || "N/A",
      };
    });
  }, [data]);

  const columns: ColumnsType<any> = [
    {
      title: <span className="text-xs flex justify-center">C.Id</span>,
      dataIndex: "id",
      width: 100,
      key: "id",
      render: (text) => (
        <div className="flex justify-center font-medium text-xs">{text}</div>
      ),
    },
    {
      title: <span className="text-xs flex justify-center">Campaign name</span>,
      dataIndex: "activityName",
      width: 200,
      key: "activityName",
      render: (text, record) => (
        <div className="flex flex-col gap-y-2">
          <span className="text-xs text-black font-medium  capitalize">
            {text}
          </span>
          <span className="font-medium text-xs text-gray-500">
            Created by Zebralearn
          </span>
          <StatusButton isActive={record.activityStatus} />
        </div>
      ),
    },
    {
      title: <span className="text-xs flex justify-center">Products</span>,
      key: "bookName",
      width: 150,
      render: (record) => {
        const bookImage = bookImagesMap[record?.bookId] || Book;

        return (
          <div className="flex flex-col items-center gap-2">
            <span className=" flex justify-center ">
              <img
                src={record?.bookId ? String(bookImage) : Logo}
                alt="Book"
                className="object-contain h-16 w-full"
              />
            </span>
            <div className="text-xs text-center font-medium text-black capitalize">
              {record?.bookName}
            </div>
          </div>
        );
      },
    },
    {
      title: <span className="text-xs flex justify-center">Categories</span>,
      dataIndex: "distribution",
      key: "distribution",
      width: 150,
      render: (distribution) => (
        <div className="flex flex-col gap-2">
          <div
            className={`flex flex-row gap-3 justify-between border rounded px-1 py-1 ${
              distribution === "distribution"
                ? "border-[#25D228]"
                : distribution === "branding"
                ? "border-[#1DAAEB]"
                : distribution === "marketing"
                ? "border-[#9C48DE]"
                : ""
            } border-2`}
          >
            <div
              className={`${
                distribution === "distribution"
                  ? "text-[#25D228]"
                  : distribution === "branding"
                  ? "text-[#1DAAEB]"
                  : distribution === "marketing"
                  ? "text-[#9C48DE]"
                  : ""
              } text-center text-xs w-full capitalize`}
            >
              {distribution}
            </div>
          </div>
        </div>
      ),
    },
    {
      title: <span className="text-xs flex justify-center">Channel</span>,
      dataIndex: "channels",
      key: "channels",
      width: 150,
      render: (channels) => (
        <div className="flex  justify-center">
          <div>
            {channels?.split(",")?.map((item: string, index: number) => {
              const channelImage = ChannelsImages[item];
              return (
                <div
                  key={index}
                  className="flex gap-x-2 items-center justify-center"
                >
                  <ShouldRender check={channels !== "N/A"}>
                    <span className=" flex justify-center ">
                      <img
                        src={channelImage as string}
                        alt="Book"
                        className="object-contain h-8 w-full"
                      />
                    </span>
                  </ShouldRender>

                  <span className=" text-xs font-medium whitespace-nowrap w-full text-start">
                    {item}
                  </span>
                </div>
              );
            })}
            <ShouldRender check={channels?.split(",").length > 4}>
              <div>
                <span className=" text-xs  w-full text-gray-400  cursor-pointer">
                  & more Channels
                </span>
              </div>
            </ShouldRender>
          </div>
        </div>
      ),
    },
    {
      title: <span className="text-xs flex justify-center">Budget</span>,
      dataIndex: "budget",
      width: 100,
      key: "budget",
      render: (text) => (
        <div className="font-medium text-base text-center flex justify-center text-black whitespace-nowrap">
          {text}
        </div>
      ),
    },
    {
      title: <span className="text-xs flex justify-center">Amount Spent</span>,
      width: 150,
      dataIndex: "totalIncome",
      key: "totalIncome",
      render: (text) => (
        <div className="font-medium text-base text-center flex justify-center text-red-500">
          {text}
        </div>
      ),
    },
    {
      title: (
        <span className="text-xs flex justify-center">Start & End Date</span>
      ),
      width: 150,
      key: "startDate",
      render: (record) => {
        console.log(record, "here1234");
        return (
          <div className="flex justify-center">
            <div>
              <span className=" flex gap-x-1 text-xs text-black font-medium">
                <div className="italic font-normal">S- </div>
                {moment(record?.startDate).format("DD-MM-YYYY")}
              </span>
              <span className="flex gap-x-1 text-xs text-black font-medium">
                <div className="italic font-normal">E- </div>
                {moment(record?.endDate).format("DD-MM-YYYY")}
              </span>
            </div>
          </div>
        );
      },
    },

    {
      title: <span className="text-xs flex justify-center">Sale</span>,
      dataIndex: "quantity",
      width: 100,
      key: "quantity",
      render: (text) => (
        <div className="flex flex-col gap-1">
          <div className="text-sm text-[#9F9F9F] text-center">Quantity</div>
          <div className="text-black text-sm font-medium text-center">
            {text}
          </div>
        </div>
      ),
    },
    {
      title: <span className="text-xs flex justify-center">View</span>,
      dataIndex: "reachData",
      width: 180,
      key: "reachData",
      render: (reachData) => (
        <div>
          {reachData?.map(
            (item: { label: string; value: string }, index: number) => (
              <div key={index} className="flex justify-between w-full">
                <div className="w-full">
                  <div className="text-xs text-[#878787]">{item?.label}</div>
                </div>
                <div className="flex justify-end w-full text-xs text-black font-medium whitespace-nowrap">
                  {item?.value}
                </div>
              </div>
            )
          )}
        </div>
      ),
    },
    {
      title: <span className="text-xs flex justify-center">Action</span>,
      key: "action",
      fixed: "right",
      width: 120,
      render: (record) => (
        <div className="flex flex-col gap-2">
          <ButtonDefault
            size={0}
            variant={ButtonVariants.PRIMARY}
            className="w-full justify-center shadow-sm rounded-md "
            onClick={() => {
              setEditId(record?.id);
              setIsModalOpenUpload(true);
            }}
          >
            <span className="flex items-center w-full font-medium justify-center  py-1 whitespace-nowrap">
              Upload
            </span>
          </ButtonDefault>
          <ButtonDefault
            size={0}
            variant={ButtonVariants.WHITE}
            className=" shadow-sm rounded-md "
            onClick={() => {
              setEditId(record?.id);
              setIsModalOpen(true);
            }}
          >
            <span className="flex items-center font-medium gap-x-2 py-1  whitespace-nowrap">
              <PencilIcon className="w-3.5 h-3.5" /> Edit
            </span>
          </ButtonDefault>
          <ButtonDefault
            size={0}
            variant={ButtonVariants.WHITE}
            className="shadow-sm rounded-md "
            onClick={() => {
              setCampaignId(record?.id);
              setIsModalOpenLogs(true);
            }}
          >
            <span className="w-full flex items-center font-medium gap-x-2 justify-center py-1 whitespace-nowrap">
              View Logs
            </span>
          </ButtonDefault>
        </div>
      ),
    },
  ];
  const usersWithKeys = formattedData?.map((user) => ({
    ...user,
    key: user.id.toString(),
  }));

  return (
    <>
      <div id="marketingTable" data-hydrate="true" className="text-xs">
        <Table
          columns={columns}
          className="tableHeader customScrollBar"
          dataSource={usersWithKeys}
          loading={loading}
          pagination={{
            current: page,
            pageSize: paginationData?.per_page || 0,
            total: paginationData?.total || 0,
            onChange: (newPage) => {
              setPage(newPage);
            },
          }}
          scroll={{ x: 1300 }}
        />
        <CreateCampaigns
          isModalOpen={isModalOpen}
          handleOk={handleOk}
          handleCancel={handleCancel}
          editId={editId as number}
        />
        <UploadModal
          campaignsId={editId as number}
          isModalOpen={isModalOpenUpload}
          handleOk={handleOkUpload}
          handleCancel={handleCancelUpload}
        />

        <ViewLogsModal
          campaignId={campaignId as string}
          isModalOpen={isModalOpenLogs}
          handleCancel={handleCancelLogs}
        />
      </div>
    </>
  );
};

export default MarketingCampaignTable;
