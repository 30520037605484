import React, { useEffect, useState, useMemo } from "react";
import { Helmet } from "react-helmet";
import { pageTitle } from "../../components/shared/basic/customHead";
import SprintTable from "../../components/AllTask/SprintTable";
import { getMyTasks } from "../../services/task";
import { notify } from "../../components/shared/basic/notify";
import { MyTaskData } from "../../utils/interfaces/user";
import { useLocation, useNavigate } from "react-router-dom";
import AddTaskModal from "../../components/AllTask/AddTaskModal";
import extractErrorMessage from "../../components/shared/basic/formateError";

const MyTaskPage = () => {
  const [loading, setLoading] = useState<boolean>();
  const [isModalOpenAddTask, setIsModalOpenAddTask] = useState(false);
  const [data, setDate] = useState<MyTaskData[]>();
  const router = useNavigate();
  const location = useLocation();

  const query = new URLSearchParams(location.search);

  const handleGetAllMyTask = async () => {
    try {
      setLoading(true);
      const response = await getMyTasks();
      const { data } = response;
      // setSprints(data);
      setDate(data);
      console.log(data, "My Tasks");
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleOk = () => {
    setIsModalOpenAddTask(false);
  };

  const handleCancel = () => {
    setIsModalOpenAddTask(false);
  };

  useEffect(() => {
    if (location?.search === "?isModalOpenAddTask=true") {
      setIsModalOpenAddTask(true);
      query.delete("isModalOpenAddTask");
      router({ search: `?${query.toString()}` });
    }
  }, [location?.search]);

  useEffect(() => {
    handleGetAllMyTask();
  }, []);

  const formattedData = useMemo(() => {
    return data?.map((dataItem) => {
      return {
        id: dataItem.task.id,
        title: dataItem.task.title,
        status: dataItem.task.status,
        description: dataItem.task.description,
        due_date: dataItem.task.due_date,
        created_by: {
          name: dataItem.task.created_by.name,
        },
        module_tasks: [], // Default or empty array
        user_tasks: [], // Default or empty array
        module: {
          name: dataItem.task.module.name as string,
        },
      };
    });
  }, [data]);

  return (
    <div className="flex flex-col gap-y-2">
      <Helmet>
        <title>{pageTitle("All Channels")}</title>
      </Helmet>

      <SprintTable
        tasks={formattedData || []}
        isAssigns={false}
        loading={loading as boolean}
      />
      <AddTaskModal
        isModalOpen={isModalOpenAddTask}
        handleOk={handleOk}
        handleCancel={handleCancel}
        sprintId={null}
      />
    </div>
  );
};
export default MyTaskPage;
