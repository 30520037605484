export const Endpoints = {
  login: `/api/login`,
  users: `/api/user`,
  allUsers: `/api/users`,
  roles: `/api/roles`,
  createUser: `/api/users`,
  getUser: `/api/users`,
  getTeams: `/api/user-modules`,
  addMember: `/api/team/bulk`,
  getAllSprints: `/api/sprints`,
  updateTask: `/api/tasks`,
  createSprint: `/api/sprints`,
  getAllSprintsList: `/api/sprints/all`,
  createTask: `/api/tasks`,
  getModules: `/api/modules/index`,
  getSprintsAllLessData: `/api/sprints/all/index`,
  getMyTasks: `/api/tasks/user`,
  getAllCampaigns: `/api/campaigns`,
  getAllModuleProcess: `/api/modules/task/progress-data`,
  getAllProducts: `/api/base-products`,
  getAllLevel: `/api/campaign-levels`,
  createCampaign: `/api/campaigns`,
  getAllChannels: `/api/channels`,
  downloadFile: `/api/documents`,
  getSingleCampaign: `/api/campaigns`,
  uploadCampaign: `/api/campaigns/upload`,
};
