import React from "react";
import { ProcessDataInterface } from "../../../utils/interfaces/user";
import { Avatar } from "antd";
import { letterColors } from "../../../utils/data";
import { TruckIcon } from "@heroicons/react/outline";
import { ShopOutlined } from "@ant-design/icons";

enum ListNames {
  Todo_list = "Todo List",
  In_progress_list = "In Progress List",
  Blocked_list = "Blocked List",
  Done_list = "Done List",
}

const listDisplayNames: Record<ListNames, string> = {
  [ListNames.Todo_list]: "Todo List",
  [ListNames.In_progress_list]: "In Progress List",
  [ListNames.Blocked_list]: "Blocked List",
  [ListNames.Done_list]: "Done List",
};

interface Props {
  data: ProcessDataInterface;
  index: number;
}

const CampaignsCard: React.FC<Props> = ({ data, index }) => {
  const colors: { bg: string; text: string }[] = [
    { bg: "bg-purple-200", text: "text-purple-600" },
    { bg: "bg-gray-200", text: "text-gray-900" },
    { bg: "bg-red-200", text: "text-red-600" },
    { bg: "bg-green-200", text: "text-green-500" },
  ];

  const bgColors: { bg: string }[] = [
    { bg: "bg-green-200" },
    { bg: "bg-purple-200" },
    { bg: "bg-blue-200" },
  ];

  const myCardData = {
    list: {
      [ListNames.Todo_list]: data?.todo_count,
      [ListNames.In_progress_list]: data?.in_progress_count,
      [ListNames.Blocked_list]: data?.blocked_count,
      [ListNames.Done_list]: data?.done_count,
    },
  };
  return (
    <div className="rounded-lg bg-white w-full ">
      <div
        className={`h-12 ${bgColors[index]?.bg} rounded-t-lg px-4 flex justify-between`}
      >
        <TruckIcon className="text-white w-10 h-10 mb-8 -rotate-12" />
        <ShopOutlined
          style={{ fontSize: "30px" }}
          className="text-white w-10 mt-6"
        />
      </div>
      <div className="px-5 pt-4">
        <div className="flex gap-x-2">
          <span className="flex justify-center">
            <Avatar
              size={30}
              style={{
                backgroundColor:
                  (letterColors as Record<string, string>)[
                    data?.name?.charAt(0)?.toUpperCase()
                  ] || "#000000",
              }}
            >
              <span className="capitalize">{data?.name.charAt(0)}</span>
            </Avatar>
          </span>
          <span className="flex items-center">
            <p className="font-medium capitalize">{data.name}</p>
          </span>
        </div>
        <div className="flex flex-col w-full gap-y-1 py-2 pt-4">
          {Object.keys(myCardData.list).map((key, index) => {
            return (
              <div key={key} className="">
                <div
                  className={` m-2 px-4 rounded-lg font-medium  flex justify-between w-full`}
                >
                  <h2 className="font-bold text-sm text-black">
                    {listDisplayNames[key as ListNames]}
                  </h2>
                  <span
                    className={`${colors[index]?.bg} font-medium ${colors[index]?.text} text-xs flex justify-center items-center px-4 rounded-full`}
                  >{`${myCardData.list[key as ListNames]}`}</span>
                </div>
                <div className="border-b border-gray-200" />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CampaignsCard;
