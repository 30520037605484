import React, { useEffect, useState } from "react";
import { Collapse, Modal, Table, notification } from "antd";
import { getLogs } from "../../../../services/campaigns";
import { ColumnProps } from "antd/es/table";
import { LogData } from "../../../../utils/interfaces/user";
import moment from "moment";
import ButtonDefault, { ButtonVariants } from "../../../shared/basic/button";
import UploadModal from "../UploadModal";
import { ShouldRender } from "../../../shared/basic/ShouldRender";
import { ClipboardCheckIcon } from "@heroicons/react/outline";

interface Props {
  isModalOpen: boolean;
  handleCancel: () => void;
  campaignId: string;
}

const ViewLogsModal: React.FC<Props> = ({
  isModalOpen,
  handleCancel,
  campaignId,
}) => {
  const [loadingLogs, setLoadingLogs] = useState<boolean>(false);
  const [logs, setLogs] = useState<LogData[]>([]);
  const [isModalOpenUpload, setIsModalOpenUpload] = useState(false);
  const [copied, setCopied] = useState(false);
  const [error, setError] = useState<string[]>();

  const handleCopyClick = () => {
    // Get the text content from the element
    const textToCopy = document?.getElementById("errorText")?.innerText ?? "";

    // Create a textarea element, set its value to the text, and append it to the document
    const textarea = document.createElement("textarea");
    textarea.value = textToCopy;
    document.body.appendChild(textarea);

    // Select the text in the textarea
    textarea.select();

    // Execute the copy command
    document.execCommand("copy");

    // Remove the textarea from the document
    document.body.removeChild(textarea);

    // Set copied state to true for visual feedback
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 1500);
  };

  const handleOkUpload = () => {
    setIsModalOpenUpload(false);
  };

  const handleCancelUpload = () => {
    setIsModalOpenUpload(false);
  };

  const handleClose = () => {
    handleCancel();
  };

  const handleGetLogs = async () => {
    try {
      setLoadingLogs(true);

      const response = await getLogs(campaignId as string);

      const { data } = response;
      setLogs(data);
    } catch (error) {
      const message = "Something went wrong, please try again.";

      notification.error({
        message: "Error",
        description: message,
      });
    } finally {
      setLoadingLogs(false);
    }
  };

  useEffect(() => {
    if (campaignId && isModalOpen) {
      handleGetLogs();
    }
  }, [campaignId, isModalOpen]);

  const columns: Array<ColumnProps<LogData>> = [
    {
      title: (
        <span className="text-xs flex justify-center whitespace-nowrap">
          Channel Name
        </span>
      ),
      width: 100,
      render: (record: LogData) => (
        <div className="flex justify-center font-medium text-xs">
          {record.properties.channel}
        </div>
      ),
    },
    {
      title: (
        <span className="text-xs flex justify-center whitespace-nowrap">
          Imported At
        </span>
      ),
      dataIndex: "created_at",
      width: 100,
      key: "importedAt",
      render: (text: string) => (
        <div className="flex justify-center whitespace-nowrap font-medium text-xs">
          {moment(text).format("DD MMM YYYY")}
        </div>
      ),
    },
    {
      title: (
        <span className="text-xs flex justify-center whitespace-nowrap">
          Created
        </span>
      ),
      width: 100,
      key: "created",
      render: (record: LogData) => (
        <div className="flex justify-center font-medium text-xs">
          {record?.properties?.imported}
        </div>
      ),
    },
    {
      title: (
        <span className="text-xs flex justify-center whitespace-nowrap">
          Updated
        </span>
      ),
      width: 100,
      render: (record: LogData) => (
        <div className="flex justify-center font-medium text-xs">
          {record?.properties?.updated}
        </div>
      ),
    },
    {
      title: (
        <span className="text-xs flex justify-center whitespace-nowrap">
          Failed
        </span>
      ),
      width: 100,
      key: "failed",
      render: (record: LogData) => (
        <div className="flex justify-center font-medium text-xs">
          {record?.properties?.failed}
        </div>
      ),
    },
    {
      title: (
        <span className="text-xs flex justify-center whitespace-nowrap">
          Skip Import
        </span>
      ),
      width: 100,
      key: "skip",
      render: (record: LogData) => (
        <div className="flex justify-center font-medium text-xs">
          {record?.properties?.skip}
        </div>
      ),
    },
    {
      title: (
        <span className="text-xs flex justify-center whitespace-nowrap">
          Actions
        </span>
      ),
      width: 100,
      key: "action",
      render: (record: LogData) => (
        <div className="flex gap-x-2 ">
          <ShouldRender check={record?.properties?.failed !== 0}>
            <ButtonDefault
              size={0}
              variant={ButtonVariants.PRIMARY}
              onClick={() => {
                handleCancel();
                setIsModalOpenUpload(true);
              }}
            >
              <span className="px-2 py-1 whitespace-nowrap">Re-Upload</span>
            </ButtonDefault>
          </ShouldRender>
          <ShouldRender check={record?.properties?.errors}>
            <ButtonDefault
              size={0}
              variant={ButtonVariants.WHITE}
              onClick={() => {
                setError(record?.properties?.errors);
              }}
            >
              <span className="px-2 py-1 whitespace-nowrap">View Error</span>
            </ButtonDefault>
          </ShouldRender>
        </div>
      ),
    },
  ];

  return (
    <>
      <Modal
        title={`Logs`}
        open={isModalOpen}
        onCancel={handleClose}
        className="w-full customModal flex justify-center"
        footer={null}
      >
        <div className="flex justify-center flex-col gap-y-2">
          <Table
            dataSource={logs}
            columns={columns}
            loading={loadingLogs}
            pagination={false}
            size="small"
            className="tableHeader"
          />
          <ShouldRender check={error}>
            <Collapse
              defaultActiveKey={["1"]}
              className="bg-gray-700 text-white customCollapseError"
              items={[
                {
                  key: "1",
                  label: (
                    <div className="flex justify-between text-white">
                      {" "}
                      <span>Errors</span>{" "}
                      <button
                        className="flex gap-x-2 items-center"
                        onClick={handleCopyClick}
                      >
                        <span>
                          <ClipboardCheckIcon className="w-4 text-white" />
                        </span>
                        <span> {copied ? "Copied!" : "Copy"}</span>
                      </button>
                    </div>
                  ),
                  children: (
                    <div id="errorText" className="bg-black p-4">
                      {error &&
                        error?.map((item, index) => (
                          <span key={index} className="">
                            {item}
                          </span>
                        ))}
                    </div>
                  ),
                },
              ]}
            />
          </ShouldRender>
        </div>
      </Modal>
      <UploadModal
        campaignsId={Number(campaignId) as number}
        isModalOpen={isModalOpenUpload}
        handleOk={handleOkUpload}
        handleCancel={handleCancelUpload}
      />
    </>
  );
};

export default ViewLogsModal;
