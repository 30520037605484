import { Modal, Select, Spin, message } from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { notify } from "../../../shared/basic/notify";
import ButtonDefault, { ButtonVariants } from "../../../shared/basic/button";
import {
  getAllChannelsData,
  uploadCampaign,
} from "../../../../services/campaigns";
import { InboxOutlined, FileTextOutlined } from "@ant-design/icons";
import { ExclamationCircleIcon, TrashIcon } from "@heroicons/react/outline";
import extractErrorMessage from "../../../shared/basic/formateError";

interface Props {
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  campaignsId: number;
}

interface ChannelDataInterface {
  id: number;
  name: string;
}

const UploadModal: React.FC<Props> = ({
  isModalOpen,
  handleOk,
  handleCancel,
  campaignsId,
}) => {
  const [loading, setLoading] = useState<boolean>();
  const [loadingUpload, setLoadingUpload] = useState<boolean>();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [select, setSelect] = useState<string>();
  const [channelData, setChannelData] = useState<ChannelDataInterface[]>([]);

  const [image, setImage] = useState<string | null>(null);
  const [imageData, setImageData] = useState<File | undefined>();
  const [fileKey, setFileKey] = useState<number>(0);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];

    if (selectedFile) {
      const reader = new FileReader();

      reader.onload = (event) => {
        if (event.target) {
          const imageName = selectedFile.name as string;
          setImage(imageName);
          setImageData(selectedFile);
          message.success(`${selectedFile.name} file uploaded successfully.`);
          setFileKey((prevKey) => prevKey + 1); // Change the key to force reset
        }
      };

      reader.readAsDataURL(selectedFile);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      setLoadingUpload(true);
      await uploadCampaign({
        campaign_id: campaignsId,
        channel: select as string,
        file: imageData as File,
      });
      notify("Uploaded Successfully!", "success");
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      handleOk();
      handleClose();
      setLoadingUpload(false);
    }
  };

  const handleClose = () => {
    setSelect(undefined);
    setImage(null);
    setImageData(undefined);
    handleCancel();
  };

  const handleGetAllChannels = async () => {
    try {
      setLoading(true);

      const response = await getAllChannelsData();

      const { data } = response;
      setChannelData(data);
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadSample = async () => {
    try {
      // Fetch the file content using the relative path from the public folder
      const response = await fetch("/meta_file_sample.csv");
      const fileContent = await response.text();

      // Create a Blob from the file content
      const blob = new Blob([fileContent], { type: "text/csv" });

      // Create a temporary link element
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "meta_file_sample.csv";

      // Trigger a click on the link to start the download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    }
  };
  const formateChannelValues = useMemo(() => {
    return channelData?.map((team) => ({
      label: <span className="capitalize">{team?.name}</span>,
      value: team?.name,
    }));
  }, [channelData]);

  useEffect(() => {
    handleGetAllChannels();
  }, []);
  const handleDivClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <Modal
      title={"Upload"}
      open={isModalOpen}
      onOk={handleSubmit}
      onCancel={handleClose}
      footer={
        <div className="flex gap-x-3 justify-end mt-4 border-t pt-2">
          <ButtonDefault
            size={1}
            variant={ButtonVariants.WHITE}
            onClick={handleClose}
          >
            <span className="px-2 py-1">Cancel</span>
          </ButtonDefault>
          <ButtonDefault
            size={1}
            disabled={!imageData || !select || loadingUpload}
            variant={ButtonVariants.PRIMARY}
            loading={loadingUpload}
            onClick={handleSubmit}
          >
            <span className="px-6 py-1">Submit</span>
          </ButtonDefault>
        </div>
      }
    >
      <form onSubmit={handleSubmit}>
        {loading ? (
          <div
            className="h-40 flex items-center justify-center"
            style={{ textAlign: "center" }}
          >
            <Spin size="large" />
          </div>
        ) : (
          <div className="flex flex-col gap-y-2 border-t pt-4">
            <div
              className="flex justify-center items-center cursor-pointer text-gray-700 transition-all duration-400 ease-in-out hover:text-primary bg-gray-50 hover:border-primary border-2 p-5 border-dashed rounded-lg"
              onClick={handleDivClick}
            >
              <div>
                {!image ? (
                  <label className="cursor-pointer">
                    <p className="ant-upload-drag-icon flex justify-center cursor-pointer">
                      <InboxOutlined className="text-5xl" />
                    </p>
                    <p className="ant-upload-text cursor-pointer">
                      Click to this area to upload
                    </p>
                  </label>
                ) : (
                  <div>
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setImage(null);
                      }}
                      className="flex justify-end hover:text-red-700"
                    >
                      <TrashIcon className="w-4" />
                    </div>

                    <div className="flex flex-col gap-y-4 justify-center items-center px-10 ">
                      <FileTextOutlined className="text-3xl" />
                      <span>{image}</span>
                    </div>
                  </div>
                )}

                <input
                  key={fileKey}
                  type="file"
                  id="fileInput"
                  ref={fileInputRef}
                  className="cursor-pointer"
                  // TO-DO ".xl*"
                  accept=".xlsx, .xls, .csv, .xlsm, .xlsb, .xltx"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
              </div>
            </div>
            <div className="flex flex-col gap-y-1.5 pt-4">
              <span className="text-sm font-medium">Download Samples</span>
              <div className="flex gap-4">
                <Select
                  size="middle"
                  placeholder="Select Channel"
                  value={select}
                  onChange={(value) => setSelect(value)}
                  style={{ width: "100%" }}
                  options={formateChannelValues}
                />

                <ButtonDefault
                  size={1}
                  disabled={select === undefined}
                  variant={ButtonVariants.PRIMARY}
                  onClick={() => handleDownloadSample()} // Handle form submission
                >
                  <span className="px-2 whitespace-nowrap">
                    Download Samples
                  </span>
                </ButtonDefault>
              </div>
              <span className="flex gap-x-2 pt-1">
                <ExclamationCircleIcon className="text-yellow-400 w-4" />
                <span className="text-yellow-500  text-sx">
                  Please download the samples to ensure the correct format!
                </span>
              </span>
              {/* {errors.name && (
                <span className="text-red-500">{errors.name}</span>
              )} */}
            </div>
          </div>
        )}
      </form>
    </Modal>
  );
};

export default UploadModal;
