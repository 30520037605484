import React, { useEffect, useState } from "react";
import { ShouldRender } from "../../shared/basic/ShouldRender";
import { Link, useLocation } from "react-router-dom";

const Navbar: React.FC = () => {
  const [activeOption, setActiveOption] = useState("");
  const location = useLocation();
  const routePath = location?.pathname ?? "";
  const options = [
    { title: "All tasks", value: "/marketing/all-tasks" },
    { title: "All Channels", value: "/marketing/all-channels" },
    { title: "Campaigns", value: "/marketing/campaigns" },
  ];

  useEffect(() => {
    const currentPath = routePath;
    const matchedOption = options.find(
      (option) => option.value === currentPath
    );
    if (matchedOption) {
      setActiveOption(matchedOption.title);
    } else {
      setActiveOption("");
    }
  }, [routePath]);

  return (
    <div className="flex justify-between gap-x-5 items-center p-4">
      {options?.map((option, index) => (
        <Link
          key={index}
          to={option.value}
          className="hover:text-gray-600 hover:cursor-pointer"
        >
          <div className="relative">
            <div
              className={`px-4 py-2 cursor-pointer whitespace-nowrap text-xs font-medium `}
              onClick={() => setActiveOption(option.title)}
            >
              {option.title}
            </div>
            <ShouldRender check={activeOption === option.title}>
              <div className="w-full absolute -bottom-4 duration-500 ease-in-out">
                <div className="h-1.5 bg-black rounded-t-full" />
              </div>
            </ShouldRender>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default Navbar;
