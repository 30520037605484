import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { pageTitle } from "../../components/shared/basic/customHead";
import AllSprintTable from "../../components/AllSprints/AllSprintTable";
import { notify } from "../../components/shared/basic/notify";
import { DatePicker, Spin } from "antd";
import {
  getAllModuleProcessData,
  getSprintsAllLessData,
} from "../../services/sprints";
import {
  ProcessDataInterface,
  SprintLessDataInterface,
} from "../../utils/interfaces/user";
import { PaginationProps } from "../../hooks/useUsers";
import CampaignsCard from "../../components/marketing/CampaignsCard";
import { Dayjs } from "dayjs";
import extractErrorMessage from "../../components/shared/basic/formateError";
import { convertToIndianCommaFormat } from "../../components/marketing/campaigns-table/CampaignTable";
import AddTaskModal from "../../components/AllTask/AddTaskModal";
import { useLocation, useNavigate } from "react-router-dom";

const { RangePicker } = DatePicker;

export const Card = ({
  data,
}: {
  data: {
    color: string;
    image: string;
    icon: string;
    heading: string;
    active_campaigns: number;
    campaigns_count: number;
    total_amount_spent: number;
  };
}) => (
  <div
    className={`flex flex-col w-full h-48 rounded-xl ${
      data.color === "sky" && "bg-sky-400"
    } ${data.color === "green" && "bg-green-400"} ${
      data.color === "purple" && "bg-purple-400"
    } `}
  >
    <div className="relative">
      <img
        src={data.image}
        alt="Card"
        className="h-28 w-28 absolute right-0 rounded-tr-xl"
      />
      <img
        src={data.icon}
        alt="Icon"
        className="h-8 w-8 mx-auto mb-2 absolute top-5 right-5"
      />
    </div>

    <div className="flex justify-between flex-col h-full p-4 text-white font-medium">
      <span>{data.heading}</span>

      <span className="flex flex-col gap-y-2">
        <span className="text-2xl font-bold">{data.active_campaigns}</span>
        <span>Current live campaign </span>
      </span>
      <div className="flex justify-between">
        <span className="flex flex-col gap-y-2">
          <span className="text-2xl font-bold">{data?.campaigns_count}</span>
          <span>Total campaign created</span>
        </span>
        <span className="flex flex-col gap-y-2">
          <span className="text-2xl font-bold whitespace-nowrap">
            {data?.total_amount_spent
              ? `₹ ${convertToIndianCommaFormat(
                  Number(data?.total_amount_spent?.toFixed(2))
                )}`
              : "N/A"}
          </span>
          <span className="capitalize flex justify-end">Total Expenses</span>
        </span>
      </div>
    </div>
  </div>
);

const AllSprintsPage = () => {
  const [loading, setLoading] = useState<boolean>();
  const [isModalOpenAddSprint, setIsModalOpenAddSprint] = useState(false);
  const [isModalOpenAddTask, setIsModalOpenAddTask] = useState(false);
  const [sprintData, setSprintData] = useState<SprintLessDataInterface[]>([]);
  const [from, setFrom] = useState<string>();
  const [to, setTo] = useState<string>();
  const [paginationData, setPaginationData] = useState<PaginationProps>();
  const [page, setPage] = useState<number>();
  const [data, setData] = useState<ProcessDataInterface[]>();
  const router = useNavigate();
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const handleGetAllSprintsLessData = async () => {
    try {
      setLoading(true);
      const fromDate = from;
      const toDate = to;
      const response = await getSprintsAllLessData(
        fromDate as string,
        toDate as string,
        page as number
      );
      const { data } = response;
      setSprintData(data?.data);

      setPaginationData({
        current_page: data?.current_page,
        per_page: data?.per_page,
        total: data?.total,
      });
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleOk = () => {
    setIsModalOpenAddTask(false);
  };

  const handleCancel = () => {
    setIsModalOpenAddTask(false);
  };

  useEffect(() => {
    if (location?.search === "?isModalOpenAddTask=true") {
      setIsModalOpenAddTask(true);
      query.delete("isModalOpenAddTask");
      router({ search: `?${query.toString()}` });
    }
  }, [location?.search]);

  const handleGetAllSprints = async () => {
    try {
      setLoading(true);
      const response = await getAllModuleProcessData();
      const { data } = response;
      setData(data);
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetAllSprintsLessData();
  }, [from, to, isModalOpenAddSprint, page]);

  useEffect(() => {
    handleGetAllSprints();
  }, [isModalOpenAddSprint, isModalOpenAddTask]);

  const onRangeChange = (
    dates: null | (Dayjs | null)[],
    dateStrings: string[]
  ) => {
    if (dates) {
      setFrom(dateStrings[0]);
      setTo(dateStrings[1]);
    } else {
      console.log("Clear");
    }
  };

  return (
    <div className="flex flex-col gap-y-2">
      <Helmet>
        <title>{pageTitle("All Sprints")}</title>
      </Helmet>
      <div className="flex justify-between w-full">
        <span className="text-xl font-medium">Sprints</span>
        <RangePicker size="large" onChange={onRangeChange} />
      </div>
      <div className="border-b  border-gray-300 w-full pt-1 " />
      {loading ? (
        <div
          className="h-40 flex items-center justify-center"
          style={{ textAlign: "center" }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <div className="flex flex-col gap-y-4 pb-2">
          <span className="flex gap-x-5 justify-between">
            {data?.map((card, index) => {
              return <CampaignsCard key={index} data={card} index={index} />;
            })}
          </span>
        </div>
      )}
      <AllSprintTable
        page={page as number}
        setPage={setPage}
        isModalOpenAddSprint={isModalOpenAddSprint}
        setIsModalOpenAddSprint={setIsModalOpenAddSprint}
        paginationData={paginationData as PaginationProps}
        tasks={sprintData}
      />
      <AddTaskModal
        isModalOpen={isModalOpenAddTask}
        handleOk={handleOk}
        handleCancel={handleCancel}
        sprintId={null}
      />
    </div>
  );
};
export default AllSprintsPage;
