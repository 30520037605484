import React, { useState } from "react";
import { Table, Empty, Select, Avatar, Modal, Tooltip } from "antd";
import { letterColors } from "../../utils/data";
import { Task } from "../../utils/interfaces/user";
import ButtonDefault, { ButtonVariants } from "../shared/basic/button";
import { notify } from "../shared/basic/notify";
import { updateTask } from "../../services/teams";
import moment from "moment";
import { ShouldRender } from "../shared/basic/ShouldRender";
import extractErrorMessage from "../shared/basic/formateError";
import { ColumnGroupType, ColumnType } from "antd/es/table";

interface BadgeProps {
  value: string;
}

interface Props {
  tasks: Task[];
  loading?: boolean;
  isAssigns?: boolean;
}
interface AvatarProps {
  results: string[];
}

const AvatarGroup: React.FC<AvatarProps> = ({ results }) => {
  return (
    <div className="flex justify-center">
      {results.slice(0, 4).map((result, index) => {
        const text = result;
        let name = "";

        if (text) {
          const words = text.split(" ");

          if (words.length >= 1) {
            name += (words[0] || "").charAt(0);
          }

          if (words.length >= 2) {
            name += (words[1] || "").charAt(0);
          }
        }

        name = name.toUpperCase();

        return (
          <div className="flex -space-x-1" key={index}>
            <div className="inline-block h-5 w-5 rounded-full ring-2 ring-white cursor-pointer">
              <Tooltip title={text}>
                <Avatar
                  size={30}
                  style={{
                    backgroundColor:
                      (letterColors as Record<string, string>)[
                        name?.charAt(0)
                      ] || "#000000",
                    border: "2px solid",
                  }}
                >
                  {name}
                </Avatar>
              </Tooltip>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const Badge: React.FC<BadgeProps> = ({ value }) => {
  let bgColor = "bg-gray-300 text-gray-800";

  if (value === "branding") {
    bgColor = "bg-sky-100 text-sky-400";
  } else if (value === "marketing") {
    bgColor = "bg-fuchsia-100 text-fuchsia-500";
  } else if (value === "distribution") {
    bgColor = "bg-green-100 text-green-500";
  } else if (value === "Low") {
    bgColor = "bg-green-100 text-green-500";
  } else if (value === "Medium") {
    bgColor = "bg-orange-100 text-orange-500";
  } else if (value === "High") {
    bgColor = "bg-red-100 text-red-500";
  }

  return (
    <span
      className={`inline-block px-6 font-medium text-xs py-2 rounded-full capitalize ${bgColor}`}
    >
      {value}
    </span>
  );
};

const SprintTable: React.FC<Props> = ({
  tasks,
  loading = false,
  isAssigns = true,
}) => {
  const [status, setStatus] = useState<{ value: string; id: number }>({
    value: "",
    id: 0,
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTask, setSelectedTask] = useState<Task>();

  const columns = [
    {
      title: <span className="text-xs flex justify-center">Task Heading</span>,
      dataIndex: "title", // Assuming the task title corresponds to 'heading'
      maxWidth: "45%",
      key: "title",
      render: (text: string) => (
        <div className="text-xs truncate font-medium">{text}</div>
      ),
    },
    {
      title: <span className="text-xs flex justify-center">Team category</span>,
      key: "status",

      render: (record: Task) => {
        return (
          <div className="flex justify-center">
            <Badge
              value={
                record.module_tasks.length
                  ? (record.module_tasks[0]?.module?.name as string)
                  : "Individual Task"
              }
            />
          </div>
        );
      },
    },
    {
      title: <span className="text-xs flex justify-center">Module</span>,
      key: "moduleName",

      render: (record: Task) => {
        return (
          <div className="flex flex-col gap-2">
            <div
              className={`flex flex-row gap-3 justify-between border rounded px-1 py-1 ${
                record?.module?.name === "distribution"
                  ? "border-[#25D228]"
                  : record?.module?.name === "branding"
                  ? "border-[#1DAAEB]"
                  : record?.module?.name === "marketing"
                  ? "border-[#9C48DE]"
                  : ""
              } border-2`}
            >
              <div
                className={`${
                  record?.module?.name === "distribution"
                    ? "text-[#25D228]"
                    : record?.module?.name === "branding"
                    ? "text-[#1DAAEB]"
                    : record?.module?.name === "marketing"
                    ? "text-[#9C48DE]"
                    : ""
                } text-center text-xs w-full capitalize`}
              >
                {record?.module?.name}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: <span className="text-xs flex justify-center">Created By</span>,
      key: "created_by",
      render: (record: Task) => {
        const text = record?.created_by?.name;
        let result = "";

        if (record?.created_by?.name) {
          const words = text.split(" ");

          if (words.length >= 1) {
            result += (words[0] || "").charAt(0);
          }

          if (words.length >= 2) {
            result += (words[1] || "").charAt(0);
          }
        }

        result = result.toUpperCase();

        return (
          <div className="flex gap-x-2 cursor-pointer items-center justify-center">
            <Tooltip title={text}>
              <Avatar
                size={30}
                className=" "
                style={{
                  backgroundColor:
                    (letterColors as Record<string, string>)[text?.charAt(0)] ||
                    "#000000",
                }}
              >
                {result}
              </Avatar>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: (
        <span className="text-xs flex justify-center whitespace-nowrap">
          Task Status
        </span>
      ),
      key: "status",
      width: 150,
      render: (record: Task) => (
        <div className="flex justify-center px-2 py-1 whitespace-nowrap">
          <Select
            placeholder="Select Status"
            value={
              status.value && status.id === record?.id
                ? status.value
                : record?.status
            }
            onChange={(value) => {
              setStatus({ value: value, id: record?.id });
              handleUpdateStatus(record?.id, value);
            }}
            style={{ width: "100%" }}
            options={[
              { value: "Todo", label: "To-Do" },
              { value: "In Progress", label: "In-Process" },
              { value: "Done", label: "Done" },
              { value: "Blocked", label: "Blocked" },
            ]}
          />
        </div>
      ),
    },
    isAssigns && {
      title: <span className="text-xs flex justify-center">Assignees</span>,
      width: 200,
      key: "assignees",
      render: (record: Task) => {
        let namesArray: string[] = [];
        if (record?.module_tasks?.length) {
          namesArray =
            record?.module_tasks[0]?.module?.team?.map(
              (item) => item?.user?.name
            ) || [];
        } else {
          namesArray = record?.user_tasks?.map((item) => item.user.name) || [];
        }

        return (
          <div className="flex gap-x-2 justify-center items-center">
            <AvatarGroup results={namesArray} />
            <ShouldRender check={namesArray?.length > 4}>
              <div className="text-xs flex items-center h-full pt-2 pl-1">
                +{namesArray?.length - 4} Sign
              </div>
            </ShouldRender>
          </div>
        );
      },
    },
    {
      title: <span className="text-xs flex justify-center">Actions</span>,
      width: 200,
      key: "assignees",
      render: (record: Task) => {
        return (
          <div className="flex justify-center">
            <ButtonDefault
              size={0}
              variant={ButtonVariants.WHITE}
              onClick={() => handleViewDetails(record)}
            >
              <span className="px-2 py-1">View Details</span>
            </ButtonDefault>
          </div>
        );
      },
    },
  ].filter(Boolean) as (ColumnType<Task> | ColumnGroupType<Task>)[];
  const handleUpdateStatus = async (
    id: number | null,
    status: string | null
  ) => {
    try {
      const response = await updateTask(status as string, id as number);

      if (response) {
        notify("Status Updated successfully!", "success");
      }
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    }
  };
  const handleViewDetails = (task: Task) => {
    setSelectedTask(task);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const dueDate = moment(selectedTask?.due_date);

  const isOverdue = dueDate.isBefore(moment()); // Check if due date is before the current date

  // Define a CSS class for red color if the task is overdue
  const redColorClass = isOverdue ? "text-red-400" : "";
  return (
    <div className="overflow-auto px-4">
      <Table
        dataSource={tasks} // Pass the 'tasks' prop as the dataSource
        className="tableHeader rounded-none"
        columns={columns}
        loading={loading}
        pagination={false}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<span>No Tasks</span>}
              className=""
            />
          ),
        }}
      />
      <Modal
        title={
          <div className="flex flex-col gap-y-2 justify-start  ">
            <span>Task Description</span>
            <span className={`text-sm flex gap-x-2`}>
              Due Date:{" "}
              <p className={`text-sm ${redColorClass}`}>
                {dueDate.format("DD MMMM YYYY")}
              </p>
            </span>
          </div>
        }
        open={isModalVisible}
        onCancel={handleModalClose}
        footer={false}
      >
        {selectedTask && (
          <div>
            <p>{selectedTask.description}</p>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default SprintTable;
