export interface DataSourceItem {
  key: string;
  id: number;
  name: string;
  email: string;
  phone: string;
  avatar: string | null;
  active_status: number;
  country_code: string;
  created_at: string;
  updated_at: string;
  gender: string;
  role_id: string;
  roles: { name: string }[];
}

export interface RolesItems {
  id: string;
  name: string;
  guard_name: string;
  created_at: string;
  updated_at: string;
}

export enum UserRolesEnum {
  "module-manager" = "Module Manager",
  "admin-manager" = "Admin Manager",
  "employee" = "Employee",
}

export const userRolesEnumMapping: Record<string, string> = {
  "module-manager": UserRolesEnum["module-manager"],
  "admin-manager": UserRolesEnum["admin-manager"],
  employee: UserRolesEnum["employee"],
};

export interface UserData {
  name: string;
  email?: string;
  gender: string;
  password?: string;
  role_id?: string;
  phoneNumber: string;
  role: string | undefined;
}

export interface teamsInterface {
  id: number;
  name: string;
  type: string;
  permission: null | string;
  created_at: string;
  updated_at: string;
  deleted_at: null | string;
  team: Team[];
}

interface Team {
  id: number;
  user_id: number;
  module_id: number;
  created_at: null | string;
  updated_at: null | string;
  deleted_at: null | string;
  user: {
    id: number;
    name: string;
    email: string;
    email_verified_at: null | string;
    phone: string;
    avatar: null | string;
    banner: null | string;
    date_of_birth: null | string;
    role_id: number;
    gender: string;
    active_status: number;
    country_code: string;
    whatsapp_id: null | string;
    telegram_id: null | string;
    slack_id: null | string;
    phone_verified_at: null | string;
    whatsapp_verified_at: null | string;
    telegram_verified_at: null | string;
    slack_verified_at: null | string;
    created_at: string;
    updated_at: string;
    deleted_at: null | string;
  };
}

export interface CampaignData {
  id: number;
  campaign_name: string;
  channel_name: string | null;
  user_id: number;
  channel_id: number | null;
  campaign_level_id: number;
  campaign_level_name: string;
  channel_campaigns_sum_amount_spent: number | null;
  channel_campaigns_sum_impressions: number | null;
  channel_campaigns_sum_purchases: number | null;
  channel_campaigns_sum_reach: number | null;
  distinct_channel_names: string | null;
  module_id: number;
  base_project_id: number;
  budget: number;
  module_channel_id: number | null;
  module_sub_category_id: number | null;
  sub_category_id: number | null;
  sub_category_name: string | null;
  sp_id: number;
  action_date: string | null;
  amount: number | null;
  spent: number | null;
  reach: number | null;
  impressions: number | null;
  purchases: number | null;
  cost_per_purchases: number | null;
  meta: string | null;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  start_date: string;
  end_date: string;
  module: {
    id: number;
    name: string;
    type: string;
    permission: string | null;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
  };
  campaign_level: {
    id: number;
    name: string;
    is_active: boolean;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
  };
  base_product: {
    id: number;
    name: string;
    description: string;
    sp_id: number;
    type: string;
    sub_type: string;
    meta: string | null;
    extra: string | null;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
  };
}

export interface Task {
  id: number;
  title: string;
  status: string;
  description: string;
  due_date?: string;
  created_by: {
    name: string;
    id?: string;
  };

  module?: {
    name: string;
  };

  module_tasks: {
    module: {
      name: string;
      team: { user: { name: string } }[];
    };
  }[];
  user_tasks: {
    user: { name: string };
  }[];
  // Add other task properties here
}

export interface Sprint {
  id: number;
  name: string;
  start_date: string;
  end_date: string;
  tasks: Task[];
  // Add other sprint properties here
}

export interface SprintStaticData {
  id: number;
  sprintNumber: number;
  startDateEndDate: string;
  tasksInSprint: number;
  completionPercentage: string;
}

export interface TaskCreate {
  title: string;
  status: "Todo" | "In Progress" | "Blocked" | "Done" | null;
  description: string;
  due_date: string;
  sprint_id: number | null;
  module_id: number | undefined;
  user_id: number | undefined;
}

export interface SprintLessDataInterface {
  id: number;
  name: string;
  start_date: string;
  end_date: string;
  active: boolean;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  tasks_count: number;
  completed_tasks: number;
}

export interface MyTaskData {
  id: number;
  user_id: number;
  task_id: number;
  is_complete: boolean;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  task: {
    id: number;
    title: string;
    status: string;
    description: string;
    is_complete: boolean;
    due_date: string;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
    sprint_id: number;
    created_by: { name: string };
    module: {
      name: string;
    };
    user_tasks?: {
      module: {
        name: string;
        team: { user: { name: string } }[];
      };
    }[];
  };
  user_tasks: {
    user: { name: string };
  }[];
}

export interface ProcessDataInterface {
  id: number;
  name: string;
  type: string;
  permission: string;
  created_at: string;
  updated_at: string;
  deleted_at: null | string;
  todo_count: number;
  in_progress_count: number;
  done_count: number;
  blocked_count: number;
}

export interface ProductDataInterface {
  created_at: string;
  deleted_at: null | string;
  description: string;
  extra: string;
  id: number;
  meta: string;
  name: string;
  sp_id: number;
  sub_type: string;
  type: string;
  updated_at: string;
}

export interface LogData {
  id: number;
  log_name: string;
  description: string;
  subject_type: string | null;
  event: string | null;
  subject_id: number | null;
  causer_type: string;
  causer_id: number;
  properties: {
    skip: number;
    total: number;
    errors: string[];
    failed: number;
    channel: string;
    updated: number;
    imported: number;
    file_path: string;
    batch_uuid: number;
    execution_time: string;
  };
  batch_uuid: number | null;
  created_at: string;
  updated_at: string;
}

export interface AxiosErrorObject {
  response: {
    data: {
      message: string;
    };
  };
}
