import React from "react";
import { Helmet } from "react-helmet";
import { pageTitle } from "../../components/shared/basic/customHead";
import AllUser from "../../components/AllUser";

const AllUsersPage = () => {
  return (
    <div className="flex flex-col gap-y-2">
      <Helmet>
        <title>{pageTitle("All Users")}</title>
      </Helmet>
      <AllUser />
    </div>
  );
};
export default AllUsersPage;
