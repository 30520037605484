import Cookies from "js-cookie";
import { Endpoints } from "../network";
import apiClient from "./base";

export const getAllCampaigns = async (page: number) => {
  const response = await apiClient.get(
    `${Endpoints.getAllCampaigns}?page=${page}`
  );
  return response?.data;
};

export const getSingleCampaigns = async (id: number) => {
  const response = await apiClient.get(`${Endpoints.getSingleCampaign}/${id}`);
  return response?.data;
};

export const getAllProductsData = async () => {
  const response = await apiClient.get(`${Endpoints.getAllProducts}`);
  return response?.data;
};

export const getAllLevelsData = async () => {
  const response = await apiClient.get(`${Endpoints.getAllLevel}`);
  return response?.data;
};

export const createCampaign = async (data: {
  campaign_name: string;
  campaign_level_id?: string;
  module_id: string;
  base_project_id: string;
  start_date: string;
  end_date: string;
  desc: string;
  budget?: number;
}): Promise<any> => {
  const {
    campaign_name,
    campaign_level_id,
    module_id,
    base_project_id,
    start_date,
    end_date,
    desc,
    budget,
  } = data;
  const id = Cookies.get("user_id");
  const payload = {
    campaign_name,
    campaign_level_id,
    base_project_id,
    start_date,
    module_id,
    end_date,
    desc,
    user_id: Number(id),
    budget: budget ? budget : null,
  };

  const filteredPayload = Object.fromEntries(
    Object.entries(payload).filter(([, value]) => value !== undefined)
  );
  const response = await apiClient.post(
    `${Endpoints.createCampaign}`,
    filteredPayload
  );

  return response;
};

export const editAllCampaign = async (data: {
  campaign_name: string;
  campaign_level_id?: string;
  module_id: string;
  base_project_id: string;
  start_date: string;
  end_date: string;
  desc: string;
  budget?: number;
  campId: number;
}): Promise<any> => {
  const {
    campaign_name,
    campaign_level_id,
    module_id,
    base_project_id,
    start_date,
    end_date,
    desc,
    budget,
    campId,
  } = data;
  const id = Cookies.get("user_id");
  const payload = {
    campaign_name,
    campaign_level_id,
    base_project_id,
    start_date,
    module_id,
    end_date,
    desc,
    user_id: Number(id),
    budget: budget ? budget : null,
  };

  const filteredPayload = Object.fromEntries(
    Object.entries(payload).filter(([, value]) => value !== undefined)
  );
  const response = await apiClient.post(
    `${Endpoints.createCampaign}/${campId}`,
    filteredPayload
  );

  return response;
};

export const editCampaign = async (data: {
  end_date: string;
  id: number;
  budget?: number | undefined;
}): Promise<any> => {
  const { end_date, id, budget } = data;
  const payload = {
    end_date,
    budget,
  };

  const filteredPayload = Object.fromEntries(
    Object.entries(payload).filter(([, value]) => value !== undefined)
  );

  const response = await apiClient.post(
    `${Endpoints.createCampaign}/${id}`,
    filteredPayload
  );

  return response;
};

export const uploadCampaign = async (
  data:
    | FormData
    | {
        campaign_id: number;
        channel: string;
        file: File;
      }
): Promise<any> => {
  let formData: FormData;

  if (data instanceof FormData) {
    formData = data;
  } else {
    formData = new FormData();
    formData.append("campaign_id", String(data.campaign_id));
    formData.append("channel", data.channel);
    formData.append("file", data.file);
  }

  const response = await apiClient.post(
    `${Endpoints.uploadCampaign}`,
    formData
  );

  return response;
};

export const getAllChannelsData = async () => {
  const response = await apiClient.get(`${Endpoints.getAllChannels}`);
  return response?.data;
};

export const getLogs = async (id: string) => {
  const response = await apiClient.get(`/api/campaigns/${id}/logs`);
  return response?.data;
};

export const getDownloadFile = async (id: string) => {
  const response = await apiClient.get(`${Endpoints.downloadFile}/${id}`);
  return response?.data;
};
