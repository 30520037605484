import {
  DatePicker,
  DatePickerProps,
  Input,
  Modal,
  Select,
  Spin,
  notification,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import ButtonDefault, { ButtonVariants } from "../../shared/basic/button";
import TextArea from "antd/es/input/TextArea";
import {
  createCampaign,
  editAllCampaign,
  editCampaign,
  getAllLevelsData,
  getAllProductsData,
  getLogs,
  getSingleCampaigns,
} from "../../../services/campaigns";
import { notify } from "../../shared/basic/notify";
import {
  LogData,
  ProductDataInterface,
  teamsInterface,
} from "../../../utils/interfaces/user";
import dayjs, { Dayjs } from "dayjs";
import { ShouldRender } from "../../shared/basic/ShouldRender";
import { getTeams } from "../../../services/user";
import extractErrorMessage from "../../shared/basic/formateError";

const { RangePicker } = DatePicker;

interface Props {
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  editId: number;
}

const CreateCampaigns: React.FC<Props> = ({
  isModalOpen,
  handleOk,
  handleCancel,
  editId,
}) => {
  const [dates, setDates] = useState<{ startDate: string; endDate: string }>();
  const [editBudget, setEditBudget] = useState<number>();
  const [loading, setLoading] = useState<boolean>();
  const [teams, setTeams] = useState<teamsInterface[]>([]);
  const [productData, setProductData] = useState<ProductDataInterface[]>([]);
  const [levelData, setLevelData] = useState<{ id: number; name: string }[]>(
    []
  );
  const [logs, setLogs] = useState<LogData[]>([]);

  const handleGetLogs = async () => {
    try {
      const response = await getLogs(String(editId));

      const { data } = response;
      setLogs(data);
    } catch (error) {
      const message = "Something went wrong, please try again.";

      notification.error({
        message: "Error",
        description: message,
      });
    }
  };

  useEffect(() => {
    if (editId) {
      handleGetLogs();
    }
  }, [editId]);

  const [formData, setFormData] = useState({
    campaign_name: "",
    campaign_level_id: undefined,
    module_id: undefined,
    base_project_id: undefined,
    start_date: "",
    end_date: "",
    desc: "",
    budget: undefined as number | undefined,
  });

  const [errors, setErrors] = useState({
    campaign_name: "",
    campaign_level_id: "",
    module_id: "",
    base_project_id: "",
    start_date: "",
    end_date: "",
    desc: "",
  });

  const handleClose = () => {
    setFormData({
      campaign_name: "",
      campaign_level_id: undefined,
      module_id: undefined,
      base_project_id: undefined,
      start_date: "",
      end_date: "",
      desc: "",
      budget: undefined,
    });
    setErrors({
      campaign_name: "",
      campaign_level_id: "",
      module_id: "",
      base_project_id: "",
      start_date: "",
      end_date: "",
      desc: "",
    });
    handleCancel();
  };
  const getTeam = async () => {
    try {
      setLoading(true);
      const response = await getTeams();
      const { data } = response;
      setTeams(data?.data);
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      setLoading(false);
    }
  };

  const onRangeChange = (
    dates: null | (Dayjs | null)[],
    dateStrings: string[]
  ) => {
    if (dates) {
      setFormData({
        ...formData,
        start_date: dateStrings[0] as string,
        end_date: dateStrings[1] as string,
      });
    } else {
      console.log("Clear");
    }
  };

  const handleCloseEdit = () => {
    handleClose();
    setLogs([]);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    // Validation
    const validationErrors = {
      campaign_name: "",
      campaign_level_id: "",
      module_id: "",
      base_project_id: "",
      start_date: "",
      end_date: "",
      desc: "",
    };

    if (!formData.campaign_name) {
      validationErrors.campaign_name = "Name is required";
    }
    if (!formData.campaign_level_id) {
      validationErrors.campaign_level_id = "Level is required";
    }
    if (!formData.module_id) {
      validationErrors.module_id = "Team is required";
    }
    if (!formData.campaign_level_id) {
      validationErrors.campaign_level_id = "Team is required";
    }
    if (!formData.start_date) {
      validationErrors.start_date = "Date is required";
    }
    if (!formData.end_date) {
      validationErrors.end_date = "Date is required";
    }
    if (!formData.desc) {
      validationErrors.desc = "Description is required";
    }

    setErrors(validationErrors);

    if (Object.values(validationErrors).every((error) => !error)) {
      handleCreateCampaigns();
    }
  };

  const handleEditAll = (e: React.FormEvent) => {
    e.preventDefault();

    // Validation
    const validationErrors = {
      campaign_name: "",
      campaign_level_id: "",
      module_id: "",
      base_project_id: "",
      start_date: "",
      end_date: "",
      desc: "",
    };

    if (!formData.campaign_name) {
      validationErrors.campaign_name = "Name is required";
    }
    if (!formData.campaign_level_id) {
      validationErrors.campaign_level_id = "Level is required";
    }
    if (!formData.module_id) {
      validationErrors.module_id = "Team is required";
    }
    if (!formData.campaign_level_id) {
      validationErrors.campaign_level_id = "Team is required";
    }
    if (!formData.start_date) {
      validationErrors.start_date = "Date is required";
    }
    if (!formData.end_date) {
      validationErrors.end_date = "Date is required";
    }
    if (!formData.desc) {
      validationErrors.desc = "Description is required";
    }

    setErrors(validationErrors);

    if (Object.values(validationErrors).every((error) => !error)) {
      handleEditAllCamp();
    }
  };

  const handleSubmitEdit = (e: React.FormEvent) => {
    e.preventDefault();
    if (logs.length) {
      handleEditCampaigns();
    } else {
      handleEditAll(e);
    }
  };

  const handleCreateCampaigns = async () => {
    try {
      const data = {
        ...formData,
        campaign_level_id: formData.campaign_level_id || "",
        module_id: formData.module_id || "",
        base_project_id: formData.base_project_id || "",
      };
      const response = await createCampaign(data);

      if (response) {
        notify("Campaigns Added successfully!", "success");
      }
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      handleOk();
      handleClose();
    }
  };

  const handleEditAllCamp = async () => {
    try {
      const data = {
        ...formData,
        campaign_level_id: formData.campaign_level_id || "",
        module_id: formData.module_id || "",
        base_project_id: formData.base_project_id || "",
        campId: editId,
      };
      const response = await editAllCampaign(data);

      if (response) {
        notify("Campaigns Added successfully!", "success");
      }
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      handleCloseEdit();
    }
  };

  const handleEditCampaigns = async () => {
    try {
      const formateBody = {
        budget: editBudget,
        end_date: dates?.endDate || "",
        id: editId,
      };
      const response = await editCampaign(formateBody);

      if (response) {
        notify("Campaigns Edited successfully!", "success");
      }
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      handleCloseEdit();
    }
  };
  const parsedDate = dayjs(dates?.startDate);

  // Function to disable dates that are before the parsed date
  const disabledDate = (current: dayjs.ConfigType) => {
    // Disable dates before the parsed date, including the parsed date
    return current && dayjs(current).startOf("day") < parsedDate.startOf("day");
  };

  const selectProjectsOptions = useMemo(() => {
    return productData?.map((product) => ({
      label: product?.name,
      value: product?.id,
    }));
  }, [productData]);

  const selectLevelOptions = useMemo(() => {
    return levelData?.map((level) => ({
      label: level?.name,
      value: level?.id,
    }));
  }, [productData]);

  const handleGetAllProductsList = async () => {
    try {
      setLoading(true);

      const response = await getAllProductsData();

      const { data } = response;
      setProductData(data);
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      setLoading(false);
    }
  };

  const selectOptionsTeams = useMemo(() => {
    return teams?.map((team) => ({
      label: <span className="capitalize">{team?.name}</span>,
      value: team.id,
    }));
  }, [teams]);
  const handleGetAllLevels = async () => {
    try {
      setLoading(true);

      const response = await getAllLevelsData();

      const { data } = response;
      //   setProductData(data);
      setLevelData(data);
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetAllProductsList();
    handleGetAllLevels();
    getTeam();
  }, [isModalOpen]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const parsedValue = Number(inputValue);

    if (!isNaN(parsedValue) && parsedValue !== undefined) {
      if (editId) {
        setEditBudget(parsedValue);
      } else {
        setFormData({ ...formData, budget: parsedValue });
      }
    }
  };

  const handleGetSingleCampaign = async (editId: number, logData?: boolean) => {
    try {
      setLoading(true);

      const response = await getSingleCampaigns(editId);

      const { data } = response;

      if (logData) {
        setFormData({
          campaign_name: data.campaign_name,
          campaign_level_id: data.campaign_level_id,
          module_id: data.module_id,
          base_project_id: data.base_project_id,
          start_date: data.start_date,
          end_date: data.end_date,
          desc: data.desc,
          budget: data.budget,
        });
      } else {
        setDates({ startDate: data?.start_date, endDate: data?.end_date });
      }
      setEditBudget(data?.budget || null);
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (editId && !logs.length) {
      handleGetSingleCampaign(editId, true);
    } else if (editId) {
      handleGetSingleCampaign(editId);
    }
  }, [editId, logs]);

  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    if (date) {
      setDates({ startDate: dates?.startDate as string, endDate: dateString });
    }
  };

  return (
    <Modal
      title={editId ? "Edit Campaign" : "Create Campaign"}
      open={isModalOpen}
      onOk={handleOk}
      footer={
        <div className="flex gap-x-3 justify-end mt-4">
          <ButtonDefault
            size={1}
            variant={ButtonVariants.WHITE}
            onClick={() => {
              editId ? handleCloseEdit() : handleClose();
            }}
          >
            <span className="px-2 py-1">Cancel</span>
          </ButtonDefault>
          <ButtonDefault
            size={1}
            variant={ButtonVariants.PRIMARY}
            // disabled={label === ''}
            onClick={(e) => {
              !editId ? handleSubmit(e) : handleSubmitEdit(e);
            }}
          >
            <span className="px-6 py-1">{!editId ? "Create" : "Edit"}</span>
          </ButtonDefault>
        </div>
      }
      onCancel={() => {
        editId ? handleCloseEdit() : handleClose();
      }}
    >
      {loading ? (
        <div
          className="h-40 flex items-center justify-center"
          style={{ textAlign: "center" }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <div className="flex flex-col gap-y-2 border-t pt-4">
          <ShouldRender check={!logs?.length}>
            <div className="flex flex-col gap-y-1.5">
              <span className="text-sm font-medium">Campaign name</span>
              <span>
                <Input
                  name="campaign_name"
                  value={formData.campaign_name}
                  onChange={(e) =>
                    setFormData({ ...formData, campaign_name: e.target.value })
                  }
                  size="large"
                  className=" "
                />
                {errors.campaign_name && (
                  <span className="text-red-500">{errors.campaign_name}</span>
                )}
              </span>
            </div>
            <div className="flex flex-col gap-y-1.5">
              <span className="text-sm font-medium">Select Level</span>
              <span>
                <Select
                  size={"large"}
                  placeholder="Select Level"
                  value={formData.campaign_level_id}
                  onChange={(value) =>
                    setFormData({ ...formData, campaign_level_id: value })
                  }
                  style={{ width: "100%" }}
                  options={selectLevelOptions}
                />
                {errors.campaign_level_id && (
                  <span className="text-red-500">
                    {errors.campaign_level_id}
                  </span>
                )}
              </span>
            </div>
            <ShouldRender
              check={
                formData?.campaign_level_id && +formData.campaign_level_id === 2
              }
            >
              <div className="flex flex-col gap-y-1.5">
                <span className="text-sm font-medium">Link Product</span>
                <span>
                  <Select
                    size={"large"}
                    placeholder="Select Projects"
                    value={formData.base_project_id}
                    onChange={(value) =>
                      setFormData({ ...formData, base_project_id: value })
                    }
                    style={{ width: "100%" }}
                    options={selectProjectsOptions}
                  />
                </span>
              </div>
            </ShouldRender>

            <div className="flex flex-col gap-y-1.5">
              <span className="text-sm font-medium">Select Team</span>
              <span>
                <Select
                  size="large"
                  allowClear
                  style={{ width: "100%" }}
                  value={formData.module_id}
                  placeholder="Select member from list"
                  onChange={(value) =>
                    setFormData({ ...formData, module_id: value })
                  }
                  options={selectOptionsTeams}
                />
                {errors.module_id && (
                  <span className="text-red-500">{errors.module_id}</span>
                )}
              </span>
            </div>

            <div className="flex flex-col gap-y-1.5">
              <span className="text-sm font-medium">
                Budget <span className="text-gray-400 text-sm">(optional)</span>
              </span>
              <span>
                <Input
                  prefix="₹"
                  onChange={handleInputChange}
                  value={formData.budget}
                />
              </span>
            </div>

            <div className="flex flex-col gap-y-1.5">
              <span className="text-sm font-medium">Date</span>
              <span>
                <RangePicker
                  onChange={onRangeChange}
                  value={
                    formData?.start_date && formData?.end_date
                      ? [dayjs(formData?.start_date), dayjs(formData?.end_date)]
                      : null
                  }
                  size="large"
                  className="w-full"
                />
              </span>
            </div>
            <div className="flex flex-col gap-y-1.5">
              <span className="text-sm font-medium">Campaign Summary</span>
              <span>
                <TextArea
                  value={formData.desc ?? ""}
                  onChange={(e) =>
                    setFormData({ ...formData, desc: e.target.value })
                  }
                  className="w-full"
                  rows={4}
                />
                {errors.desc && (
                  <span className="text-red-500">{errors.desc}</span>
                )}
              </span>
            </div>
          </ShouldRender>
          <ShouldRender check={editId && logs?.length}>
            <div className="flex flex-col gap-y-1.5">
              <span className="text-sm font-medium">
                Budget <span className="text-gray-400 text-sm">(optional)</span>
              </span>
              <span>
                <Input
                  prefix="₹"
                  onChange={handleInputChange}
                  value={editBudget}
                />
              </span>
            </div>
            <span className="text-sm font-medium">Update End Date</span>
            <DatePicker
              value={dates?.endDate ? dayjs(dates.endDate) : null}
              onChange={onChange}
              placeholder="Select Due Date"
              size="large"
              disabledDate={disabledDate as any}
            />
          </ShouldRender>
        </div>
      )}
    </Modal>
  );
};

export default CreateCampaigns;
