import { Endpoints } from "../network";
import apiClient from "./base";

export const getSprints = async (fromDate: string, to: string) => {
  const response = await apiClient.get(
    `${Endpoints.getAllSprints}?from=${fromDate}&to=${to}`
  );

  return response?.data;
};

export const getSprintsAll = async () => {
  const response = await apiClient.get(`${Endpoints.getAllSprintsList}`);
  return response?.data;
};

export const getSprintsAllLessData = async (
  fromDate: string | undefined,
  to: string | undefined,
  page: number
) => {
  let queryString = `${Endpoints.getSprintsAllLessData}?page=${page}`;

  if (fromDate !== undefined) {
    queryString += `&from=${fromDate}`;
  }

  if (to !== undefined) {
    queryString += `&to=${to}`;
  }

  const response = await apiClient.get(queryString);
  return response?.data;
};

export const getAllModuleProcessData = async () => {
  const response = await apiClient.get(`${Endpoints.getAllModuleProcess}`);
  return response?.data;
};
