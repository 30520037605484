import React from "react";
import TimeSelector from "../../../components/marketing/TimeSelector";
import { Helmet } from "react-helmet";
import { pageTitle } from "../../../components/shared/basic/customHead";

const AllChannels = () => {
  return (
    <div className="flex flex-col gap-y-2">
      <Helmet>
        <title>{pageTitle("All Channels")}</title>
      </Helmet>
      <div>
        <TimeSelector />
        <div className="border-b  border-gray-300 w-full pt-1 " />
      </div>
      All Channels
    </div>
  );
};
export default AllChannels;
