import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PoweroffOutlined,
  SolutionOutlined,
  AppstoreOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";

import {
  Avatar,
  Button,
  Dropdown,
  Layout,
  Menu,
  SelectProps,
  Spin,
  theme,
} from "antd";

import React, { ReactNode, useEffect, useMemo, useState } from "react";

import {
  brandingIcon,
  distributionIcons,
  LogoWhite,
  marketingIcons,
  WhiteLogoIcon,
} from "../../assets";
import {
  BellIcon,
  LogoutIcon,
  PlusCircleIcon,
  PlusIcon,
} from "@heroicons/react/outline";
import { getTeams, getUser } from "../../services/user";
import { notify } from "../shared/basic/notify";
import { DownOutlined } from "@ant-design/icons";
import { useNavigate, Link, Outlet, useLocation } from "react-router-dom";
import { removeAuth } from "../../utils/lib/auth";
import ButtonDefault, { ButtonVariants } from "../shared/basic/button";
import Navbar from "./Navbar";
import { ShouldRender } from "../shared/basic/ShouldRender";
import AddTeamModal from "./Teams/AddTeamModal";
import { teamsInterface } from "../../utils/interfaces/user";
import { letterColors } from "../../utils/data";
import extractErrorMessage from "../shared/basic/formateError";

const { Header, Sider, Content } = Layout;

interface DefaultLayoutProps {
  children: ReactNode;
}

type TeamImageMapping = {
  [key: string]: any;
};

const DefaultLayout: React.FC<DefaultLayoutProps> = ({ children }) => {
  const router = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [collapsed, setCollapsed] = useState(false);
  const [teamLoading, setTeamLoading] = useState(false);
  const [team, setTeam] = useState<teamsInterface>();

  const [isModalOpenTeam, setIsModalOpenTeam] = useState(false);
  const [teams, setTeams] = useState<teamsInterface[]>([]);
  const [count, setCount] = useState(0);
  const [user, setUser] = useState<string>();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const routePath = location?.pathname ?? "";
  const isAllUser = routePath.includes("/all-users");
  const isCampaign = routePath.includes("/marketing/campaigns");
  const isAllTask = routePath.includes("/marketing/all-tasks");
  const isMyTask = routePath === "/my-tasks";
  const isAllSprints = routePath === "/marketing";

  const getActiveMenuItem = (routePath: string) => {
    if (routePath.startsWith("/marketing")) {
      return "1";
    } else if (routePath.startsWith("/all-users")) {
      return "2";
    } else if (routePath.startsWith("/my-tasks")) {
      return "3";
    } else if (routePath.startsWith("/settings")) {
      return "4";
    }
    return "1";
  };

  const selectedKey = getActiveMenuItem(routePath);

  const getUserDetails = async () => {
    await getUser()
      .then((resp) => {
        setUser(resp.data?.name);
      })
      .catch(() => {
        if (count === 0) {
          notify(
            "You are not authorized to view this page. Please Login!",
            "error"
          );
          router("/");
        }
        setCount(count + 1);
      });
  };

  useEffect(() => {
    getUserDetails();
    getTeam();
  }, []);

  useEffect(() => {
    getTeam();
  }, [isModalOpenTeam, location.pathname]);

  const teamImageMapping: TeamImageMapping = {
    branding: brandingIcon,
    distribution: distributionIcons,
    marketing: marketingIcons,
  };

  const getTeam = async () => {
    try {
      setTeamLoading(true);
      const response = await getTeams();

      const { data } = response;
      setTeams(data?.data);
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      setTeamLoading(false);
    }
  };

  const widgetMenu = (
    <Menu>
      <Menu.Item className="flex gap-x-2">
        <div className="flex gap-x-2">
          <span>
            <SolutionOutlined className="icon" />
          </span>
          <span>
            <Link
              to="/my-tasks"
              className="text-black hover:text-black no-underline"
            >
              <span className="hover:text-black">My Tasks</span>
            </Link>
          </span>
        </div>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          removeAuth();
          router("/");
        }}
        className="flex gap-x-2"
      >
        <div className="flex gap-x-2">
          <span>
            <PoweroffOutlined className="icon" />
          </span>
          <span> Sign Out</span>
        </div>
      </Menu.Item>
    </Menu>
  );

  const teamsMenu = useMemo(() => {
    return (
      <div className="py-4 flex flex-col">
        <Menu className="p-4 flex flex-col">
          <div className="font-medium text-sm px-5 my-2">TOTAL TEAMS</div>
          {teamLoading ? (
            <div
              className="h-20 flex items-center justify-center"
              style={{ textAlign: "center" }}
            >
              <Spin size="default" />
            </div>
          ) : (
            teams?.map((team) => {
              return (
                <Menu.Item
                  key={team?.id}
                  onClick={() => {
                    setIsModalOpenTeam(true);
                    setTeam(team);
                  }}
                  className="flex gap-x-2 py-4"
                >
                  <div className="flex justify-between gap-x-20 items-center">
                    <span className="flex items-center gap-x-2">
                      <img
                        src={teamImageMapping[team.name]}
                        className="w-10 text-black "
                        alt={team.name}
                      />
                      <span className="flex flex-col ">
                        <span className="font-medium capitalize">
                          {team.name}
                        </span>
                        <span className="text-xs text-gray-500">
                          {team?.team?.length} Members
                        </span>
                      </span>
                    </span>
                    <span>
                      {" "}
                      <ButtonDefault
                        size={1}
                        variant={ButtonVariants.WHITE}
                        className="w-min p-1 shadow-sm rounded-md "
                        // onClick={() => showModal()}
                      >
                        <span className="flex items-center text-xs gap-x-1 font-normal  whitespace-nowrap">
                          <PlusIcon className="w-4" />
                          Add People
                        </span>
                      </ButtonDefault>
                    </span>
                  </div>
                </Menu.Item>
              );
            })
          )}
        </Menu>
      </div>
    );
  }, [teams]);

  const handleOkTeam = () => {
    setIsModalOpenTeam(false);
  };

  const handleCancelTeam = () => {
    setIsModalOpenTeam(false);
  };

  const options: SelectProps["options"] = [];

  for (let i = 10; i < 36; i++) {
    options.push({
      value: i.toString(36) + i,
      label: i.toString(36) + i,
    });
  }

  return (
    <>
      <Layout className="">
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          width={200}
          className="site-layout-background bg-black z-40"
          style={{
            position: "fixed",
            height: "100vh",
            left: 0,
            zIndex: 1,
            backgroundColor: "black",
          }}
        >
          <div className="flex flex-col h-full justify-between">
            <div>
              <div className="demo-logo-vertical" />
              <span className=" flex justify-center px-7 pt-10  pb-5">
                <img
                  src={collapsed ? WhiteLogoIcon : LogoWhite}
                  alt="Logo"
                  className="object-contain h-6 w-full"
                />
              </span>
              <span
                className={`text-sm ${
                  !collapsed ? " px-7" : "flex justify-center text-xs"
                } text-white mb-4`}
              >
                MENU
              </span>
              <Menu
                theme="dark"
                className="bg-black mt-2 "
                mode="inline"
                selectedKeys={[selectedKey]}
              >
                <Menu.Item key="1" icon={<AppstoreOutlined className="w-6 " />}>
                  <Link to="/marketing">Dashboard</Link>
                  <div className="indicator" />
                </Menu.Item>
                <Menu.Item key="2" icon={<TeamOutlined className="w-5" />}>
                  <Link to="/all-users">All Users</Link>
                  <div className="indicator" />
                </Menu.Item>
                <Menu.Item key="3" icon={<UserOutlined className="w-5" />}>
                  <Link to="/my-tasks">My Tasks</Link>
                  <div className="indicator" />
                </Menu.Item>
                <Outlet />
              </Menu>
            </div>

            <div className="flex items-end px-5 pb-10 ">
              <ButtonDefault
                size={2}
                variant={
                  !collapsed ? ButtonVariants.PRIMARY : ButtonVariants.WHITE
                }
                className="h-min w-full  shadow-sm rounded-md "
                onClick={() => {
                  removeAuth();
                  router("/");
                }}
              >
                {!collapsed ? (
                  <span className="flex items-center text-xs gap-x-1 font-normal  whitespace-nowrap">
                    <LogoutIcon className="w-5" />
                    Logout
                  </span>
                ) : (
                  <span className="flex items-center text-xs gap-x-1 font-normal  whitespace-nowrap">
                    <LogoutIcon className="w-5" />
                  </span>
                )}
              </ButtonDefault>
            </div>
          </div>
        </Sider>
        <Layout
          style={{
            marginLeft: collapsed ? 80 : 200,
            transition: "margin-left 0.2s",
          }}
        >
          <Header
            className="z-40 flex justify-between items-center"
            style={{
              padding: 0,
              background: colorBgContainer,
              position: "sticky",
              width: "100%",
              top: 0,
            }}
          >
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: "16px",
                width: 64,
                height: 64,
              }}
            />
            <div className="flex justify-between w-full">
              <ShouldRender check={!isAllUser}>
                <div className="flex justify-center w-full">
                  <span className="flex">
                    <Navbar />
                    <span className=" flex items-center">
                      <Dropdown overlay={teamsMenu} trigger={["click"]}>
                        <ButtonDefault
                          size={1}
                          variant={ButtonVariants.UNSTYLED}
                          // onClick={handleModalCancel}
                          className="bg-gray-100 hover:bg-gray-200 "
                        >
                          <span className="px-4 py-1 text-xs flex gap-x-4 items-center font-medium">
                            Teams{" "}
                            <DownOutlined
                              className="pt-1 font-medium"
                              style={{ fontSize: "10px", fontWeight: "bold" }}
                            />
                          </span>
                        </ButtonDefault>
                      </Dropdown>
                    </span>
                  </span>
                </div>
              </ShouldRender>

              <span className="flex w-full justify-end gap-x-8 px-10 items-center">
                <ShouldRender check={isCampaign}>
                  <span>
                    <ButtonDefault
                      size={2}
                      variant={ButtonVariants.PRIMARY}
                      className="w-min p-1 shadow-sm rounded-md "
                      onClick={() => {
                        query.set("isModalOpenAddCampaign", "true");
                        router({ search: `?${query.toString()}` });
                      }}
                    >
                      <span className="flex items-center text-xs gap-x-1 font-normal  whitespace-nowrap">
                        <PlusCircleIcon className="w-5" />
                        Create Campaign
                      </span>
                    </ButtonDefault>
                  </span>
                </ShouldRender>
                <ShouldRender check={isAllTask || isAllSprints || isMyTask}>
                  <span>
                    <ButtonDefault
                      size={2}
                      variant={ButtonVariants.PRIMARY}
                      className="w-min p-1 shadow-sm rounded-md "
                      onClick={() => {
                        query.set("isModalOpenAddTask", "true");
                        router({ search: `?${query.toString()}` });
                      }}
                    >
                      <span className="flex items-center text-xs gap-x-1 font-normal  whitespace-nowrap">
                        <PlusCircleIcon className="w-5" />
                        Create Task
                      </span>
                    </ButtonDefault>
                  </span>
                </ShouldRender>
                <ShouldRender check={isAllUser}>
                  <span>
                    <ButtonDefault
                      size={2}
                      variant={ButtonVariants.PRIMARY}
                      className="w-min p-1 shadow-sm rounded-md "
                      onClick={() => {
                        query.set("isModalOpenUser", "true");
                        router({ search: `?${query.toString()}` });
                      }}
                    >
                      <span className="flex items-center text-xs gap-x-1 font-normal  whitespace-nowrap">
                        <PlusCircleIcon className="w-5" />
                        Add New User
                      </span>
                    </ButtonDefault>
                  </span>
                </ShouldRender>

                <div className="relative">
                  <BellIcon className="w-7 h-7" />
                  <div className=" absolute top-0 right-1">
                    <span className="relative flex h-2 w-2">
                      <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                      <span className="relative inline-flex rounded-full h-2 w-2 bg-red-500"></span>
                    </span>
                  </div>
                </div>
                <span className=" hover:cursor-pointer">
                  <div>
                    <Dropdown overlay={widgetMenu}>
                      <Avatar
                        size={30}
                        style={{
                          backgroundColor:
                            (letterColors as Record<string, string>)[
                              (user || "").charAt(0)
                            ] || "#000000",
                        }}
                      >
                        {(user || "").charAt(0)}
                      </Avatar>
                    </Dropdown>
                  </div>
                </span>
              </span>
            </div>
          </Header>
          <Content
            className="px-5"
            style={{
              margin: "20px 0px 20px 0px",
            }}
          >
            {children}
          </Content>
        </Layout>
      </Layout>

      <AddTeamModal
        handleCancel={handleCancelTeam}
        isModalOpen={isModalOpenTeam}
        handleOk={handleOkTeam}
        team={team}
      />
    </>
  );
};

export default DefaultLayout;
