import { Avatar, Modal, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import ButtonDefault, { ButtonVariants } from "../../shared/basic/button";
import { letterColors } from "../../../utils/data";
import { useUsers } from "../../../hooks/useUsers";
import {
  teamsInterface,
  userRolesEnumMapping,
} from "../../../utils/interfaces/user";
import { addMembers } from "../../../services/teams";
import { notify } from "../../shared/basic/notify";
import extractErrorMessage from "../../shared/basic/formateError";
import { useLocation } from "react-router-dom";

interface Props {
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  team: teamsInterface | undefined;
}

const AddTeamModal: React.FC<Props> = ({
  isModalOpen,
  handleOk,
  handleCancel,
  team,
}) => {
  const { allUsers, getAllUsers, loading } = useUsers();
  const location = useLocation();
  const [selectValue, setSelectValue] = useState<string[]>();
  const handleClose = () => {
    handleCancel();
    setSelectValue([]);
  };

  const filteredArray = allUsers?.filter((item) => {
    return !team?.team.some((idToRemove) => idToRemove?.user_id === item.id);
  });

  useEffect(() => {
    getAllUsers();
  }, [location.pathname]);

  const handleAddMember = async () => {
    try {
      const arrayOfNumbers = selectValue?.map((str) => parseInt(str, 10));
      const response = await addMembers(
        team?.id as number,
        arrayOfNumbers as number[]
      );

      if (response) {
        notify("Members add to team successfully!", "success");
      }
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      handleClose();
    }
  };
  const selectOptions = filteredArray?.map((user) => {
    let result = "";

    if (user?.name) {
      const words = user?.name?.split(" ");

      if (words.length >= 1) {
        result += (words[0] || "").charAt(0);
      }

      if (words.length >= 2) {
        result += (words[1] || "").charAt(0);
      }
    }

    result = result.toUpperCase();
    return {
      label: (
        <div className="flex gap-x-2 items-center">
          <span className="w-12 h-12 flex items-center">
            <Avatar
              size={40}
              style={{
                backgroundColor:
                  (letterColors as Record<string, string>)[
                    user?.name?.charAt(0)
                  ] || "#000000",
              }}
            >
              {result}
            </Avatar>
          </span>
          <div className="flex flex-col ">
            <span className="font-medium">{user.name}</span>
            <span className="font-medium italic text-gray-400 text-xs">
              @{userRolesEnumMapping[user?.roles[0]?.name || ""]}
            </span>
          </div>
        </div>
      ),
      value: user.id.toString(), // or whatever unique identifier you want to use
    };
  });

  const handleChange = (value: string[]) => {
    setSelectValue(value);
  };

  return (
    <Modal
      title={"Add team members"}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleClose}
      footer={
        <div className="flex gap-x-3 justify-end mt-4">
          <ButtonDefault
            size={1}
            variant={ButtonVariants.WHITE}
            onClick={handleClose}
          >
            <span className="px-2 py-1">Cancel</span>
          </ButtonDefault>
          <ButtonDefault
            size={1}
            variant={ButtonVariants.PRIMARY}
            disabled={!selectValue?.length}
            onClick={handleAddMember}
          >
            <span className="px-6 py-1">Confirm</span>
          </ButtonDefault>
        </div>
      }
    >
      <div className="flex flex-col gap-y-2">
        <span>
          {" "}
          Grow your team and work better together. Adding people to this team
          gives them access to all the team’s work.
        </span>
        <span>
          {loading ? (
            <div
              className="h-20 flex items-center justify-center"
              style={{ textAlign: "center" }}
            >
              <Spin size="default" />
            </div>
          ) : (
            <div>
              <Select
                mode="multiple"
                allowClear
                maxTagCount={6}
                style={{ width: "100%" }}
                placeholder="Select member from list"
                onChange={handleChange}
                value={selectValue}
                options={selectOptions}
                className="customSelected"
              />
              <span className="pt-4 text-xs text-gray-500">
                Add up to 10 people at a time
              </span>
            </div>
          )}
        </span>
      </div>
    </Modal>
  );
};

export default AddTeamModal;
