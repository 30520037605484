import React from "react";
// import { DatePicker, DatePickerProps, Button, Dropdown, MenuProps } from "antd";
// import { DownOutlined } from "@ant-design/icons";

interface Props {
  title?: string;
}

// const memoryOptions = [
//   { name: "All Time", inStock: true },
//   { name: "12 Months", inStock: true },
//   { name: "30 Days", inStock: true },
//   { name: "07 Days", inStock: true },
//   { name: "24 Hours", inStock: true },
// ];

// function classNames(...classes: (string | boolean | undefined)[]): string {
//   return classes.filter(Boolean).join(" ");
// }
// function RadioGroup({
//   value,
//   onChange,
// }: {
//   value: any;

//   onChange: (value: any) => void;
// }) {
//   return (
//     <div className="flex gap-x-2">
//       {memoryOptions.map((option) => {
//         return (
//           <label
//             key={option.name}
//             className={classNames(
//               option.inStock
//                 ? "cursor-pointer focus:outline-none"
//                 : "cursor-not-allowed opacity-25",
//               value.name === option.name ? "" : "",
//               value.name === option.name
//                 ? "bg-black text-white hover:bg-gray-900"
//                 : " text-gray-900 hover:bg-gray-200",
//               "flex items-center justify-center rounded-md py-2 px-4 whitespace-nowrap text-xs font-semibold uppercase sm:flex-1"
//             )}
//             onClick={() => {
//               if (option.inStock) {
//                 onChange(option);
//               }
//             }}
//           >
//             {option.name}
//           </label>
//         );
//       })}
//     </div>
//   );
// }

const TimeSelector: React.FC<Props> = ({ title = "Campaigns" }) => {
  // const [mem, setMem] = useState(memoryOptions[0]);
  // const onChange = (
  //   value: DatePickerProps["value"],
  //   dateString: [string, string] | string
  // ) => {
  //   console.log("Selected Time: ", value);
  //   console.log("Formatted Selected Time: ", dateString);
  // };

  // const onOk = (value: DatePickerProps["value"]) => {
  //   console.log("onOk: ", value);
  // };

  // const items: MenuProps["items"] = [
  //   {
  //     key: "1",
  //     label: (
  //       <a
  //         target="_blank"
  //         rel="noopener noreferrer"
  //         href="https://www.antgroup.com"
  //       >
  //         1st menu item
  //       </a>
  //     ),
  //   },
  //   {
  //     key: "2",
  //     label: (
  //       <a
  //         target="_blank"
  //         rel="noopener noreferrer"
  //         href="https://www.aliyun.com"
  //       >
  //         2nd menu item
  //       </a>
  //     ),
  //   },
  //   {
  //     key: "3",
  //     label: (
  //       <a
  //         target="_blank"
  //         rel="noopener noreferrer"
  //         href="https://www.luohanacademy.com"
  //       >
  //         3rd menu item
  //       </a>
  //     ),
  //   },
  // ];

  return (
    <div className="flex justify-between">
      <div className="flex items-center justify-between">
        <h2 className="text-lg font-medium leading-6 text-gray-900">{title}</h2>
      </div>

      {/* <div className="flex gap-x-2 items-center">
        <div className=" border rounded-md py-1 px-2">
          <RadioGroup value={mem} onChange={setMem} />
        </div>
        <div className="flex items-center">
          <DatePicker
            className="h-10 bg-gray-50 text-gray-900 font-medium placeholder:text-gray-700"
            onChange={onChange}
            onOk={onOk}
          />
        </div>
        <div>
          <Dropdown menu={{ items }} placement="bottomRight">
            <Button className="flex h-10 items-center text-gray-400 font-semibold gap-x-2">
              Sprint <DownOutlined />
            </Button>
          </Dropdown>
        </div>
      </div> */}
    </div>
  );
};
export default TimeSelector;
