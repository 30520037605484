import {
  AmazonIcon,
  Book1,
  Book2,
  Book3,
  Book4,
  Book5,
  Book6,
  Book7,
  Book8,
  Book9,
  Flipkart,
  GoogleIcon,
  Meta,
  blueCard,
  brandingIcon,
  distributionIcons,
  greenCard,
  marketingIcons,
  purpleCard,
} from "../assets";

export const CardData = {
  heading: "Branding ",
  subHeading: "Book Marketing compaign",
  list: {
    Todo_list: 10,
    In_progess_list: 2,
    Blocked_list: 2,
    Done_list: 2,
  },
};

export const countryList = [
  { name: "United States", code: "+1" },
  { name: "United Kingdom", code: "+44" },
  { name: "Canada", code: "+1" },
  { name: "Australia", code: "+61" },
  { name: "India", code: "+91" },
  { name: "Germany", code: "+49" },
  { name: "France", code: "+33" },
  { name: "Japan", code: "+81" },
  { name: "Brazil", code: "+55" },
  { name: "China", code: "+86" },
  { name: "Russia", code: "+7" },
  { name: "South Korea", code: "+82" },
  { name: "Mexico", code: "+52" },
  { name: "South Africa", code: "+27" },
  { name: "Nigeria", code: "+234" },
  { name: "Spain", code: "+34" },
  { name: "Italy", code: "+39" },
  { name: "Sweden", code: "+46" },
  { name: "Norway", code: "+47" },
  { name: "Switzerland", code: "+41" },
  { name: "New Zealand", code: "+64" },
  { name: "Greece", code: "+30" },
  { name: "Argentina", code: "+54" },
  { name: "Turkey", code: "+90" },
  { name: "Egypt", code: "+20" },
  { name: "Singapore", code: "+65" },
  { name: "Malaysia", code: "+60" },
  { name: "Thailand", code: "+66" },
  { name: "Vietnam", code: "+84" },
  { name: "Philippines", code: "+63" },
];

export const letterColors = {
  A: "#FF5733",
  B: "#33FF57",
  C: "#5733FF",
  D: "#FF33F6",
  E: "#33F6FF",
  F: "#FF5733",
  G: "#33FF57",
  H: "#5733FF",
  I: "#FF33F6",
  J: "#33F6FF",
  K: "#FF5733",
  L: "#33FF57",
  M: "#5733FF",
  N: "#FF33F6",
  O: "#33F6FF",
  P: "#FF5733",
  Q: "#33FF57",
  R: "#5733FF",
  S: "#FF33F6",
  T: "#33F6FF",
  U: "#FF5733",
  V: "#33FF57",
  W: "#5733FF",
  X: "#FF33F6",
  Y: "#33F6FF",
  Z: "#FF5733",
};

export const SprintTableData = [
  {
    id: "1",
    heading:
      "Lorem ipsum dolor sit amet consectetur. Luctus at adipiscing semper fames quisque volutpat tellus neque.",
    Team_category: "Branding",
    Created_By: "Rishabh Pandey",
    Priorities: "Low",
  },
  {
    id: "2",
    heading:
      "Lorem ipsum dolor sit amet consectetur. Luctus at adipiscing semper fames quisque volutpat tellus neque.",
    Team_category: "Marketing",
    Created_By: "Rishabh Pandey",
    Priorities: "Medium",
  },
  {
    id: "3",
    heading:
      "Lorem ipsum dolor sit amet consectetur. Luctus at adipiscing semper fames quisque volutpat tellus neque.",
    Team_category: "Distribution",
    Created_By: "Rishabh Pandey",
    Priorities: "High",
  },
  {
    id: "4",
    heading:
      "Lorem ipsum dolor sit amet consectetur. Luctus at adipiscing semper fames quisque volutpat tellus neque.",
    Team_category: "Distribution",
    Created_By: "Rishabh Pandey",
    Priorities: "High",
  },
];

export const cardsData = [
  {
    color: "sky",
    image: blueCard,
    icon: brandingIcon,
    heading: "Branding",
  },
  {
    color: "purple",
    image: purpleCard,
    icon: marketingIcons, // Use the path to the icon image
    heading: "Marketing",
  },
  {
    color: "green",
    image: greenCard,
    icon: distributionIcons, // Use the path to the icon image
    heading: "Distribution",
  },
];

export const staticTasks = [
  {
    id: 1,
    sprintNumber: 1,
    startDateEndDate: "2023-01-01 to 2023-01-15",
    tasksInSprint: 10,
    completionPercentage: "80%",
  },
  {
    id: 2,
    sprintNumber: 2,
    startDateEndDate: "2023-01-16 to 2023-01-31",
    tasksInSprint: 15,
    completionPercentage: "65%",
  },
  {
    id: 3,
    sprintNumber: 1,
    startDateEndDate: "2023-01-01 to 2023-01-15",
    tasksInSprint: 10,
    completionPercentage: "80%",
  },
  {
    id: 4,
    sprintNumber: 1,
    startDateEndDate: "2023-01-01 to 2023-01-15",
    tasksInSprint: 10,
    completionPercentage: "80%",
  },
];

export const tasks = [
  {
    id: 1,
    title: "Task 1",
    status: "Todo",
    description: "Description for Task 1",
    created_by: {
      name: "John Doe",
      id: "user1",
    },
    module_tasks: [
      {
        module: {
          name: "branding",
          team: [
            {
              user: { name: "User 1" },
            },
            {
              user: { name: "User 2" },
            },
          ],
        },
      },
    ],
    user_tasks: [
      {
        user: { name: "User 1" },
      },
      {
        user: { name: "User 2" },
      },
    ],
  },
  {
    id: 2,
    title: "Task 2",
    status: "In Progress",
    description: "Description for Task 2",
    created_by: {
      name: "Alice Smith",
      id: "user2",
    },
    module_tasks: [
      {
        module: {
          name: "marketing",
          team: [
            {
              user: { name: "User 3" },
            },
            {
              user: { name: "User 4" },
            },
          ],
        },
      },
    ],
    user_tasks: [
      {
        user: { name: "User 3" },
      },
      {
        user: { name: "User 4" },
      },
    ],
  },
  {
    id: 3,
    title: "Task 3",
    status: "Done",
    description: "Description for Task 3",
    created_by: {
      name: "Bob Johnson",
      id: "user3",
    },
    module_tasks: [],
    user_tasks: [
      {
        user: { name: "User 5" },
      },
    ],
  },
];

type BookImageMap = {
  [key: number]: React.ReactNode; // Change React.ReactNode to the actual type of your Book components or image URLs
};

export const bookImagesMap: BookImageMap = {
  1: Book1,
  2: Book2,
  3: Book3,
  4: Book4,
  5: Book5,
  6: Book6,
  7: Book7,
  8: Book8,
  9: Book9,
};

type ChannelsImageMap = {
  [key: string]: React.ReactNode; // Change React.ReactNode to the actual type of your Book components or image URLs
};

export const ChannelsImages: ChannelsImageMap = {
  Meta: Meta,
  Amazon: AmazonIcon,
  Flipkart: Flipkart,
  Google: GoogleIcon,
};
