import { DatePicker, Input, Modal } from "antd";
import React, { useState } from "react";
import ButtonDefault, { ButtonVariants } from "../../shared/basic/button";
import { Dayjs } from "dayjs";
import { createSprint } from "../../../services/teams";
import { notify } from "../../shared/basic/notify";
import extractErrorMessage from "../../shared/basic/formateError";

const { RangePicker } = DatePicker;

interface Props {
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
}

const AddSprintModal: React.FC<Props> = ({
  isModalOpen,
  handleOk,
  handleCancel,
}) => {
  const [formData, setFormData] = useState({
    name: "",
    from: "",
    to: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    from: "",
    to: "",
  });

  const onRangeChange = (
    dates: null | (Dayjs | null)[],
    dateStrings: string[]
  ) => {
    if (dates) {
      setFormData({
        ...formData,
        from: dateStrings[0] as string,
        to: dateStrings[1] as string,
      });
    } else {
      console.log("Clear");
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    // Validation
    const validationErrors = {
      name: "",
      from: "",
      to: "",
    };

    if (!formData.name) {
      validationErrors.name = "Name is required";
    }

    setErrors(validationErrors);

    if (Object.values(validationErrors).every((error) => !error)) {
      handleCreateSprint(formData?.name, formData?.from, formData?.to);
    }
  };

  const handleCreateSprint = async (
    name: string,
    start_date: string,
    end_date: string
  ) => {
    try {
      const response = await createSprint(name, start_date, end_date);

      if (response) {
        console.log("Step 2");

        notify("Sprint Added successfully!", "success");
      }
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      handleOk();
      handleClose();
    }
  };

  const handleClose = () => {
    setFormData({
      name: "",
      from: "",
      to: "",
    });
    console.log("Step 3");

    handleCancel();
  };

  return (
    <Modal
      title={"Create Sprint"}
      open={isModalOpen}
      onOk={handleSubmit}
      onCancel={handleClose}
      footer={
        <div className="flex gap-x-3 justify-end mt-4">
          <ButtonDefault
            size={1}
            variant={ButtonVariants.WHITE}
            onClick={handleClose}
          >
            <span className="px-2 py-1">Cancel</span>
          </ButtonDefault>
          <ButtonDefault
            size={1}
            variant={ButtonVariants.PRIMARY}
            onClick={handleSubmit} // Handle form submission
          >
            <span className="px-6 py-1">Create</span>
          </ButtonDefault>
        </div>
      }
    >
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col gap-y-2 border-t pt-4">
          <div className="flex flex-col gap-y-1.5">
            <span className="text-sm font-medium">Name</span>
            <Input
              name="name"
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              size="large"
              className=" "
            />
            {errors.name && <span className="text-red-500">{errors.name}</span>}
          </div>
          <div className="flex flex-col gap-y-1.5">
            <span className="text-sm font-medium">Date</span>
            <RangePicker size="large" onChange={onRangeChange} />
            {errors.from && <span className="text-red-500">{errors.from}</span>}
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default AddSprintModal;
