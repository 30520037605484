import Cookies from "js-cookie";
import { Endpoints } from "../network";
import apiClient from "./base";

export const createTask = async (data: {
  title: string;
  status: string | null;
  description: string;
  due_date: string;
  module_id: number | undefined;
  user_id: number | undefined;
  sprint_id: number | null;
  is_module_task: boolean;
}): Promise<any> => {
  const {
    title,
    description,
    due_date,
    status,
    module_id,
    user_id,
    sprint_id,
    is_module_task,
  } = data;
  const id = Cookies.get("user_id");
  const payload = {
    title,
    description,
    due_date,
    status,
    module_id,
    user_id,
    sprint_id,
    is_module_task,
    created_by: Number(id),
  };

  const filteredPayload = Object.fromEntries(
    Object.entries(payload).filter(([, value]) => value !== null)
  );
  const response = await apiClient.post(
    `${Endpoints.createTask}`,
    filteredPayload
  );

  return response;
};

export const getMyTasks = async () => {
  const id = Cookies.get("user_id");
  const response = await apiClient.get(`${Endpoints.getMyTasks}/${id}`);
  return response?.data;
};
