import React from "react";

import MarketingCampaignTable from "./campaigns-table/CampaignTable";

const MarketingDashboard = () => {
  return (
    <>
      <div className="card-header border-0">
        <div className="card-tools flex flex-col gap-7">
          <MarketingCampaignTable />
        </div>
      </div>
    </>
  );
};

export default MarketingDashboard;
