import { Endpoints } from "../network";
import apiClient from "./base";

export const addMembers = async (
  id: number | null,
  status: number[]
): Promise<any> => {
  const response = await apiClient.post(`${Endpoints.addMember}/${id}`, {
    team_users: status,
  });

  return response;
};

export const getSprints = async (
  fromDate: string | undefined,
  to: string | undefined,
  page: number
) => {
  let queryString = `${Endpoints.getAllSprints}?page=${page}`;

  if (fromDate !== undefined) {
    queryString += `&from=${fromDate}`;
  }

  if (to !== undefined) {
    queryString += `&to=${to}`;
  }

  const response = await apiClient.get(queryString);
  return response?.data;
};

export const updateTask = async (status: string, id: number): Promise<any> => {
  const response = await apiClient.post(`${Endpoints.updateTask}/${id}`, {
    status: status,
  });

  return response;
};

export const createSprint = async (
  name: string,
  start_date: string,
  end_date: string
): Promise<any> => {
  const response = await apiClient.post(`${Endpoints.createSprint}`, {
    name: name,
    start_date: start_date,
    end_date: end_date,
    active: 1,
  });

  return response;
};
export const updateSprint = async (id: number, date: string): Promise<any> => {
  const response = await apiClient.post(`${Endpoints.createSprint}/${id}`, {
    to: date,
  });

  return response;
};
