import React from "react";
import { Table, Empty } from "antd";
import { SprintLessDataInterface } from "../../utils/interfaces/user";

import ButtonDefault, { ButtonVariants } from "../shared/basic/button";
import { CalendarIcon, PencilIcon, PlusIcon } from "@heroicons/react/outline";
import { PaginationProps } from "../../hooks/useUsers";
import moment from "moment";
import AddSprintModal from "../AllTask/AddSprintModal";

interface Props {
  tasks: SprintLessDataInterface[];
  paginationData: PaginationProps;
  page: number;
  isModalOpenAddSprint: boolean;
  setIsModalOpenAddSprint: (value: boolean) => void;
  setPage: (value: number) => void;
}

const AllSprintTable: React.FC<Props> = ({
  page,
  setPage,
  tasks,
  paginationData,
  isModalOpenAddSprint,
  setIsModalOpenAddSprint,
}) => {
  const sprintsData = tasks?.map((data) => ({
    sprintName: data?.name,
    sprintNumber: data.id.toString(),
    startDateEndDate: `${moment(data.start_date).format(
      "DD-MM-YYYY"
    )} - ${moment(data.end_date).format("DD-MM-YYYY")}`,
    tasksInSprint: data.tasks_count.toString(),
    completionPercentage: `${(
      ((data?.completed_tasks || 0) / (data?.tasks_count || 0)) * 100 || 0
    ).toFixed(2)}%`,
  }));

  const handleOkSprint = () => {
    setIsModalOpenAddSprint(false);
  };
  const handleCancelSprint = () => {
    setIsModalOpenAddSprint(false);
  };

  const columns = [
    {
      title: <span className="text-xs flex justify-center">Sprint name</span>,
      dataIndex: "sprintName",
      key: "sprintName",
      render: (text: string) => {
        return <div className="flex font-medium">{text}</div>;
      },
    },
    {
      title: <span className="text-xs flex justify-center">Sprint Number</span>,
      dataIndex: "sprintNumber",
      key: "sprintNumber",
      render: (text: string) => {
        return (
          <div className="flex justify-center">
            <div className="flex justify-center text-xs bg-[#FFEEE8] text-orange-600 font-medium  w-min whitespace-nowrap px-6 rounded-full py-2">
              Sprint {text}
            </div>
          </div>
        );
      },
    },
    {
      title: (
        <span className="text-xs flex justify-center">Start & End Date</span>
      ),
      dataIndex: "startDateEndDate",
      key: "startDateEndDate",
      render: (text: string) => {
        return (
          <div className="flex justify-center font-medium text-xs gap-x-2">
            <CalendarIcon className="w-4 h-4" /> {text}
          </div>
        );
      },
    },
    {
      title: (
        <span className="text-xs flex justify-center">Tasks in Sprint</span>
      ),
      dataIndex: "tasksInSprint", // Replace with the actual data field
      key: "tasksInSprint",
      render: (text: string) => {
        return (
          <div className="flex justify-center font-medium text-xs">
            Task: {text}
          </div>
        );
      },
    },
    {
      title: (
        <span className="text-xs flex justify-center">
          Completion Percentage
        </span>
      ),
      dataIndex: "completionPercentage",
      key: "completionPercentage",
      render: (text: string) => {
        return (
          <div className="flex justify-center font-medium text-xs">
            Task Completed : {text}
          </div>
        );
      },
    },
    {
      title: (
        <div className="flex justify-center">
          {" "}
          <ButtonDefault
            size={4}
            variant={ButtonVariants.PRIMARY}
            className="w-min p-1 shadow-sm rounded-md"
            onClick={() => setIsModalOpenAddSprint(true)}
          >
            <span className="flex items-center text-sm px-2  font-normal gap-x-1  whitespace-nowrap">
              <PlusIcon className="w-4" />
              Add Sprint
            </span>
          </ButtonDefault>
        </div>
      ),
      key: "edit",
      render: () => (
        <div className="flex justify-center">
          <ButtonDefault
            size={1}
            variant={ButtonVariants.WHITE}
            // onClick={() => setSprintData(record)}
          >
            <span className="text-gray-500 items-center px-2 flex gap-x-2">
              <PencilIcon className="w-3.5 h-3.5" /> Edit
            </span>
          </ButtonDefault>
        </div>
      ),
    },
  ];

  return (
    <div className="overflow-auto text-xs">
      <Table
        dataSource={sprintsData}
        className="tableHeader rounded-none"
        columns={columns}
        pagination={{
          current: page,
          pageSize: paginationData?.per_page || 0,
          total: paginationData?.total || 0,
          onChange: (newPage) => {
            setPage(newPage);
          },
        }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<span>No Tasks</span>}
              className=""
            />
          ),
        }}
      />

      <AddSprintModal
        isModalOpen={isModalOpenAddSprint}
        handleOk={handleOkSprint}
        handleCancel={handleCancelSprint}
      />
    </div>
  );
};

export default AllSprintTable;
