import { Endpoints } from "../network";
import apiClient from "./base";

export const loginUser = async (
  email: string,
  password: string
): Promise<any> => {
  const response = await apiClient.post(Endpoints.login, {
    email,
    password,
  });

  return response;
};

export const getUser = async () => {
  const response = await apiClient.get(Endpoints.users);

  return response;
};

export const getTeams = async () => {
  const response = await apiClient.get(Endpoints.getTeams);

  return response?.data;
};

export const updateStatus = async (
  id: number | null,
  status: number | null
): Promise<any> => {
  const response = await apiClient.post(`${Endpoints.createUser}/${id}`, {
    active_status: status,
  });

  return response;
};
