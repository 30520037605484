import { DatePicker, DatePickerProps, Input, Modal, Select, Spin } from "antd";
import React, { useState, useMemo, useEffect } from "react";
import ButtonDefault, { ButtonVariants } from "../../shared/basic/button";
import {
  TaskCreate,
  teamsInterface,
  userRolesEnumMapping,
} from "../../../utils/interfaces/user";
import { notify } from "../../shared/basic/notify";
import { getSprintsAll } from "../../../services/sprints";
import TextArea from "antd/es/input/TextArea";
import { ShouldRender } from "../../shared/basic/ShouldRender";
import { getTeams } from "../../../services/user";
import dayjs from "dayjs";
import { createTask } from "../../../services/task";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import extractErrorMessage from "../../shared/basic/formateError";

interface Props {
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  sprintId: string | null;
}

interface SprintInterface {
  id: number;
  name: string;
  start_date: string;
  end_date: string;
  active: boolean;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

const AddTaskModal: React.FC<Props> = ({
  isModalOpen,
  handleOk,
  handleCancel,
  sprintId,
}) => {
  const [loading, setLoading] = useState<boolean>();
  const [sprints, setSprints] = useState<SprintInterface[]>([]);
  const [select, setSelect] = useState<string>();
  const [teams, setTeams] = useState<teamsInterface[]>([]);
  const router = useNavigate();
  const query = new URLSearchParams(location.search);

  const [formData, setFormData] = useState<TaskCreate>({
    title: "",
    status: null,
    description: "",
    due_date: "",
    module_id: undefined,
    user_id: undefined,
    sprint_id: sprintId ? Number(sprintId) : null,
  });

  const [errors, setErrors] = useState({
    title: "",
    status: "",
    description: "",
    due_date: "",
    module_id: "",
    user_id: "",
    sprint_id: "",
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Validation
    const validationErrors = {
      title: "",
      status: "",
      description: "",
      due_date: "",
      module_id: "",
      user_id: "",
      sprint_id: "",
      is_module_task: "",
    };

    if (!formData.title) {
      validationErrors.title = "Title is required";
    }

    if (!formData.status) {
      validationErrors.status = "Status is required";
    }

    if (!formData.description) {
      validationErrors.description = "Description is required";
    }

    if (!formData.due_date) {
      validationErrors.due_date = "Due Date is required";
    }

    if (select === "Team" && !formData.module_id) {
      validationErrors.module_id = "Module ID is required";
    }

    if (select === "Individual" && !formData.user_id) {
      validationErrors.user_id = "User ID is required";
    }

    if (!sprintId && !formData.sprint_id) {
      validationErrors.sprint_id = "Sprint ID is required";
    }

    setErrors(validationErrors);

    if (Object.values(validationErrors).every((error) => !error)) {
      try {
        const response = await createTask({
          ...formData,
          sprint_id: sprintId ? Number(sprintId) : formData.sprint_id,
          is_module_task: select === "Individual" ? false : true,
        });

        if (response) {
          notify("Task Added successfully!", "success");
        }
      } catch (error: any) {
        const message = extractErrorMessage(error);
        notify(message, "error");
      } finally {
        handleOk();
        handleClose();
      }
    }
  };

  const selectOptionsSprint = useMemo(() => {
    return sprints?.map((sprint) => ({
      label: (
        <div className="flex gap-x-2 items-center">
          <span className="">{sprint?.name}</span>
          <span className="text-gray-500 text-xs">
            ({moment(sprint?.start_date).format("DD MMMM YYYY")} -{" "}
            {moment(sprint?.end_date).format("DD MMMM YYYY")})
          </span>
        </div>
      ),
      value: sprint.id,
    }));
  }, [sprints]);

  const selectOptionsTeams = useMemo(() => {
    return teams?.map((team) => ({
      label: <span className="capitalize">{team?.name}</span>,
      value: team.id,
    }));
  }, [sprints]);

  const assigneeArray = useMemo(() => {
    const filteredTeamArray = teams.filter(
      (item) => item?.id === formData.module_id
    );

    return filteredTeamArray;
  }, [formData.module_id, teams]);

  const handleGetAllSprints = async () => {
    try {
      setLoading(true);
      const response = await getSprintsAll();
      const { data } = response;
      setSprints(data);
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      setLoading(false);
    }
  };

  const getTeam = async () => {
    try {
      setLoading(true);
      const response = await getTeams();

      const { data } = response;
      setTeams(data?.data);
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetAllSprints();
    getTeam();
  }, [isModalOpen]);

  const handleClose = () => {
    setFormData({
      title: "",
      status: null,
      description: "",
      due_date: "",
      module_id: undefined,
      user_id: undefined,
      sprint_id: null,
    });
    setSelect(undefined);
    query.delete("sprint_id");
    router({ search: `?${query.toString()}` });
    handleCancel();
  };

  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    if (date) {
      setFormData({ ...formData, due_date: dateString });
    }
  };

  const selectOptions = assigneeArray.flatMap((team: teamsInterface) => {
    return team.team.map((user: any) => {
      return {
        label: (
          <div className="flex gap-x-2 items-center">
            <span className="">{user?.user?.name}</span>
            <span className="italic text-gray-400 text-xs">
              @{userRolesEnumMapping[user?.user?.role?.name || ""]}
            </span>
          </div>
        ),
        value: user?.user?.id.toString(),
      };
    });
  });

  return (
    <Modal
      title={"Create Task"}
      open={isModalOpen}
      onOk={handleSubmit}
      onCancel={handleClose}
      footer={
        <div className="flex gap-x-3 justify-end mt-4">
          <ButtonDefault
            size={1}
            variant={ButtonVariants.WHITE}
            onClick={handleClose}
          >
            <span className="px-2 py-1">Cancel</span>
          </ButtonDefault>
          <ButtonDefault
            size={1}
            variant={ButtonVariants.PRIMARY}
            onClick={handleSubmit} // Handle form submission
          >
            <span className="px-6 py-1">Create</span>
          </ButtonDefault>
        </div>
      }
    >
      <form onSubmit={handleSubmit}>
        {loading ? (
          <div
            className="h-40 flex items-center justify-center"
            style={{ textAlign: "center" }}
          >
            <Spin size="large" />
          </div>
        ) : (
          <div className="flex flex-col gap-y-2 border-t pt-4">
            <div className="flex flex-col gap-y-1.5">
              <span className="text-sm font-medium">Task Heading</span>
              <Input
                name="Task Heading"
                value={formData.title as string}
                onChange={(e) =>
                  setFormData({ ...formData, title: e.target.value })
                }
                size="large"
                className=" "
              />
              {errors.title && (
                <span className="text-red-500">{errors.title}</span>
              )}
            </div>

            <div className="flex flex-col gap-y-1.5">
              <span className="text-sm font-medium">Task Status</span>
              <Select
                size="large"
                placeholder="Task Status"
                value={formData.status}
                onChange={(value) =>
                  setFormData({ ...formData, status: value })
                }
                style={{ width: "100%" }}
                options={[
                  { value: "Todo", label: "To-Do" },
                  { value: "In Progress", label: "In-Process" },
                  { value: "Done", label: "Done" },
                  { value: "Blocked", label: "Blocked" },
                ]}
              />
              {errors.status && (
                <span className="text-red-500">{errors.status}</span>
              )}
            </div>
            <div className="flex flex-col gap-y-1.5">
              <span className="text-sm font-medium">Select Type</span>
              <Select
                size="large"
                placeholder="Task Type"
                value={select}
                onChange={(value) => setSelect(value)}
                style={{ width: "100%" }}
                options={[
                  { value: "Individual", label: "Individual" },
                  { value: "Team", label: "Team" },
                ]}
              />
              {errors.status && (
                <span className="text-red-500">{errors.status}</span>
              )}
            </div>

            {/* <ShouldRender check={select === "Team"}> */}
            <div className="flex flex-col gap-y-1.5">
              <span className="text-sm font-medium">Select Team</span>
              <Select
                size="large"
                allowClear
                style={{ width: "100%" }}
                value={formData.module_id}
                placeholder="Select member from list"
                onChange={(value) =>
                  setFormData({ ...formData, module_id: value })
                }
                options={selectOptionsTeams}
              />
              {errors.status && (
                <span className="text-red-500">{errors.status}</span>
              )}
            </div>
            <ShouldRender check={select === "Individual"}>
              <div className="flex flex-col gap-y-1.5">
                <span className="text-sm font-medium">Assignee</span>
                <Select
                  size="large"
                  allowClear
                  style={{ width: "100%" }}
                  value={formData?.user_id}
                  placeholder="Select member from list"
                  onChange={(value) =>
                    setFormData({ ...formData, user_id: value })
                  }
                  options={selectOptions}
                />
                {errors.status && (
                  <span className="text-red-500">{errors.status}</span>
                )}
              </div>
            </ShouldRender>
            {/* </ShouldRender> */}
            <ShouldRender check={!sprintId}>
              <div className="flex flex-col gap-y-1.5">
                <span className="text-sm font-medium flex">
                  Select Sprint<p className="text-red-500">*</p>
                </span>
                <Select
                  size="large"
                  placeholder="Select Sprint"
                  value={formData.sprint_id}
                  onChange={(value) =>
                    setFormData({ ...formData, sprint_id: value })
                  }
                  style={{ width: "100%" }}
                  options={selectOptionsSprint}
                />
                {errors.sprint_id && (
                  <span className="text-red-500">{errors.sprint_id}</span>
                )}
              </div>
            </ShouldRender>

            <div className="flex flex-col gap-y-1.5">
              <span className="text-sm font-medium">Select Due Date</span>
              <DatePicker
                value={formData.due_date ? dayjs(formData.due_date) : null}
                onChange={onChange}
                size="large"
                placeholder="Select Due Date"
              />
              {errors.due_date && (
                <span className="text-red-500">{errors.due_date}</span>
              )}
            </div>
            <div className="flex flex-col gap-y-1.5">
              <span className="text-sm font-medium">Task Description</span>
              <TextArea
                value={formData.description ?? ""}
                onChange={(e) =>
                  setFormData({ ...formData, description: e.target.value })
                }
                size="large"
                placeholder="Enter task description"
                className="w-full"
                rows={4}
              />

              {errors.description && (
                <span className="text-red-500">{errors.description}</span>
              )}
            </div>
          </div>
        )}
      </form>
    </Modal>
  );
};

export default AddTaskModal;
