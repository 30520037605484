import axios, { AxiosRequestConfig } from "axios";
import Cookies from "js-cookie";

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 30000,
});

apiClient.interceptors.request.use((config: AxiosRequestConfig) => {
  if (config.headers) {
    config.headers["Authorization"] = `Bearer ${Cookies.get("auth_token")}`;
  }
  return config;
});

apiClient.interceptors.response.use((response: { data: null }) => {
  return response;
});

export default apiClient;
