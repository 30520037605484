import { useState } from "react";

import { notify } from "../components/shared/basic/notify";
import apiClient from "../services/base";
import { Endpoints } from "../network";
import { DataSourceItem, RolesItems, UserData } from "../utils/interfaces/user";
import extractErrorMessage from "../components/shared/basic/formateError";

export interface PaginationProps {
  current_page: number;
  per_page: number;
  total: number;
}

export const useUsers = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingEdit, setLoadingEdit] = useState<boolean>(false);
  const [allUsers, setUsers] = useState<DataSourceItem[]>();
  const [roles, setRoles] = useState<RolesItems[]>();
  const [user, setUser] = useState<DataSourceItem>();
  const [paginationData, setPaginationData] = useState<PaginationProps>();

  const getAllUsers = async (page?: number) => {
    try {
      setLoading(true);
      const response = await apiClient.get(
        `${Endpoints.allUsers}?page=${page}`
      );

      const { data } = response;

      setUsers(data?.data?.data);
      setPaginationData({
        current_page: data?.data?.current_page,
        per_page: data?.data?.per_page,
        total: data?.data?.total,
      });
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      setLoading(false);
    }
  };

  const getAllRoles = async () => {
    try {
      const response = await apiClient.get(Endpoints.roles);

      const { data } = response;

      setRoles(data?.data);
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    }
  };

  const getUser = async (id: number) => {
    try {
      setLoadingEdit(true);
      const response = await apiClient.get(`${Endpoints.getUser}/${id}`);

      const { data } = response;

      setUser(data?.data);
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      setLoadingEdit(false);
    }
  };

  const createUser = async (userData: UserData) => {
    const { name, email, gender, password, phoneNumber, role } = userData;
    try {
      const response = await apiClient.post(Endpoints.createUser, {
        name,
        email,
        gender,
        password,
        phone: phoneNumber,
        role_id: role,
      });
      if (response) {
        getAllUsers();
        notify("User Created successfully!", "success");
      }
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    }
  };

  const updateUser = async (userData: UserData, id: number | undefined) => {
    const { name, gender, password, phoneNumber, role } = userData;

    try {
      const requestData = {
        name,
        gender,
        phone: phoneNumber,
        role_id: role,
        password,
      };

      if (requestData.password === "") {
        delete requestData.password;
      }
      const response = await apiClient.post(
        `${Endpoints.createUser}/${id}`,
        requestData
      );

      if (response) {
        getAllUsers();
        notify("User Edited successfully!", "success");
      }
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    }
  };

  return {
    loading,
    allUsers,
    getAllUsers,
    getAllRoles,
    roles,
    createUser,
    getUser,
    user,
    updateUser,
    loadingEdit,
    paginationData,
  };
};
