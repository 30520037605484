import React from "react";
import "./styles/global.scss";
import { Route, Routes, HashRouter } from "react-router-dom";
import Login from "./components/Auth/Login";
import DefaultLayout from "./components/Layout";
import { ConfigProvider, ThemeConfig } from "antd";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import Marketing from "./pages/MarketingPage/Campaigns";
import AllUsers from "./pages/AllUsersPage";
import AllTask from "./pages/MarketingPage/AllTask";
import AllChannels from "./pages/MarketingPage/AllChannels";
import AllSprintsPage from "./pages/AllSprintsPage";
import MyTaskPage from "./pages/MyTaskPage";

// Define a type for your route paths
type RoutePath =
  | "/"
  | "/marketing"
  | "/all-users"
  | "/marketing/campaigns"
  | "/marketing/all-tasks"
  | "/marketing/all-channels"
  | "/my-tasks";

// Define a type for the elements that can be rendered within the routes
type RouteElement = React.ReactElement;

// Define an array of route paths where DefaultLayout should not be used
const routesWithoutDefaultLayout: RoutePath[] = ["/"];

// Custom function to determine whether to use DefaultLayout
function renderRoute(path: RoutePath, element: RouteElement): RouteElement {
  return routesWithoutDefaultLayout.includes(path) ? (
    element
  ) : (
    <DefaultLayout>{element}</DefaultLayout>
  );
}

const config: ThemeConfig = {
  token: {
    colorPrimary: "#FF5612",
  },
};

function App() {
  return (
    <ConfigProvider theme={config}>
      <HashRouter>
        <div className="App ">
          <Routes>
            <Route path="/" element={renderRoute("/", <Login />)} />
            <Route
              path="/marketing"
              element={renderRoute("/marketing", <AllSprintsPage />)}
            />
            <Route
              path="/marketing/campaigns"
              element={renderRoute("/marketing/campaigns", <Marketing />)}
            />
            <Route
              path="/my-tasks"
              element={renderRoute("/my-tasks", <MyTaskPage />)}
            />
            <Route
              path="/marketing/all-tasks"
              element={renderRoute("/marketing/all-tasks", <AllTask />)}
            />
            <Route
              path="/marketing/all-channels"
              element={renderRoute("/marketing/all-channels", <AllChannels />)}
            />
            <Route
              path="/all-users"
              element={renderRoute("/all-users", <AllUsers />)}
            />
            AllChannels
          </Routes>
        </div>
      </HashRouter>
      <ToastContainer autoClose={2000} limit={1} />
    </ConfigProvider>
  );
}

export default App;
