import { Spin, Tabs, TabsProps } from "antd";
import React, { useEffect, useState } from "react";
import MarketingDashboard from "../../../components/marketing/MarketingDashboard";
import TimeSelector from "../../../components/marketing/TimeSelector";
import { Helmet } from "react-helmet";
import { pageTitle } from "../../../components/shared/basic/customHead";
import { cardsData } from "../../../utils/data";
import { notify } from "../../../components/shared/basic/notify";
import { getModules } from "../../../services/modules";
import { Card } from "../../AllSprintsPage";
import extractErrorMessage from "../../../components/shared/basic/formateError";

const Marketing = () => {
  const [loading, setLoading] = useState<boolean>();
  const [data, setData] = useState<
    {
      active_campaigns: number;
      campaigns_count: number;
      total_amount_spent: number;
    }[]
  >([]);
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: (
        <div className="text-black customTab text-base px-5">All campaigns</div>
      ),
      children: <MarketingDashboard />,
    },
  ];

  const handleGetAllSprints = async () => {
    try {
      setLoading(true);

      const response = await getModules();

      const { data } = response;
      console.log(data, "Carddasd");
      setData(data);
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetAllSprints();
  }, []);

  const result = cardsData.map((card, index) => {
    const { active_campaigns, campaigns_count, total_amount_spent } =
      data[index] || {};
    return {
      ...card,
      active_campaigns: active_campaigns || 0,
      campaigns_count: campaigns_count || 0,
      total_amount_spent: total_amount_spent || 0,
    };
  });

  return (
    <div className="flex flex-col gap-y-2">
      <Helmet>
        <title>{pageTitle("Marketing")}</title>
      </Helmet>
      <div>
        <TimeSelector />
        <div className="border-b  border-gray-300 w-full pt-1 " />
      </div>
      <div className="flex flex-col gap-y-4">
        {loading ? (
          <div
            className="h-40 flex items-center justify-center"
            style={{ textAlign: "center" }}
          >
            <Spin size="large" />
          </div>
        ) : (
          <div className="flex w-full pb-2">
            <div className="flex space-x-4 w-full">
              {result?.map((card, index) => (
                <Card key={index} data={card} />
              ))}
            </div>
          </div>
        )}
        <div>
          <div className="relative">
            <Tabs
              defaultActiveKey="1"
              items={items}
              className="text-black customTab "
            />
            <div className="border-b  border-gray-400 w-full absolute top-12" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Marketing;
