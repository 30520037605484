import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { pageTitle } from "../../../components/shared/basic/customHead";
import SprintTable from "../../../components/AllTask/SprintTable";
import { Collapse, DatePicker, Pagination, PaginationProps, Spin } from "antd";
import ButtonDefault, {
  ButtonVariants,
} from "../../../components/shared/basic/button";
import { PlusIcon } from "@heroicons/react/outline";
import AddTaskModal from "../../../components/AllTask/AddTaskModal";
import { useLocation, useNavigate } from "react-router-dom";
import { getSprints } from "../../../services/teams";
import { notify } from "../../../components/shared/basic/notify";
import { Sprint } from "../../../utils/interfaces/user";
import moment from "moment";
import { Dayjs } from "dayjs";
import AddSprintModal from "../../../components/AllTask/AddSprintModal";
import { PaginationProps as PaginationProp } from "../../../hooks/useUsers";
import extractErrorMessage from "../../../components/shared/basic/formateError";

const { RangePicker } = DatePicker;

interface CollapseItem {
  key: string;
  label: JSX.Element;
  children: JSX.Element;
}

const generateCollapseItems = (
  sprints: Sprint[],

  router: any
): CollapseItem[] => {
  return sprints?.map((sprint) => {
    const tasks = sprint.tasks || [];
    const key = sprint.id.toString();
    const query = new URLSearchParams(location.search);

    return {
      key: key,
      label: (
        <div className="flex justify-between">
          <div>
            <span className="font-medium">{sprint.name}</span>{" "}
            <span className="text-gray-500 text-xs">
              ({moment(sprint?.start_date).format("DD MMMM YYYY")} -{" "}
              {moment(sprint?.end_date).format("DD MMMM YYYY")})
            </span>
            <span className="font-medium"> - {tasks.length} Tasks</span>
          </div>
          <ButtonDefault
            size={1}
            variant={ButtonVariants.WHITE}
            className="w-min p-1 shadow-sm rounded-md"
            onClick={() => {
              query.set("sprint_id", sprint?.id?.toString());
              router({ search: `?${query.toString()}` });
            }}
          >
            <span className="flex items-center text-xs gap-x-1 font-normal whitespace-nowrap">
              <PlusIcon className="w-4" />
              Add Tasks
            </span>
          </ButtonDefault>
        </div>
      ),
      children: <SprintTable tasks={tasks} />,
    };
  });
};

const AllTask = () => {
  const [loading, setLoading] = useState<boolean>();
  const [data, setData] = useState<Sprint[]>();
  const [isModalOpenAddTask, setIsModalOpenAddTask] = useState(false);
  const [isModalOpenAddSprint, setIsModalOpenAddSprint] = useState(false);
  const [paginationData, setPaginationData] = useState<PaginationProp>();
  const [page, setPage] = useState<number>();
  const router = useNavigate();
  const location = useLocation();
  const [from, setFrom] = useState<string>();
  const [to, setTo] = useState<string>();

  const query = new URLSearchParams(location.search);

  const collapseItems = generateCollapseItems(data as Sprint[], router);

  const handleOk = () => {
    setIsModalOpenAddTask(false);
  };
  const handleOkSprint = () => {
    setIsModalOpenAddSprint(false);
  };
  const handleCancelSprint = () => {
    setIsModalOpenAddSprint(false);
  };

  const handleCancelUser = () => {
    setIsModalOpenAddTask(false);
  };

  useEffect(() => {
    if (location?.search === "?isModalOpenAddTask=true") {
      setIsModalOpenAddTask(true);
      query.delete("isModalOpenAddTask");
      router({ search: `?${query.toString()}` });
    }
  }, [location?.search]);

  const handleGetAllSprints = async () => {
    try {
      setLoading(true);

      const fromDate = from;
      const toDate = to;

      const response = await getSprints(
        fromDate as string,
        toDate as string,
        page as number
      );

      const { data } = response;
      setData(data?.data);
      setPaginationData({
        current_page: data?.current_page,
        per_page: data?.per_page,
        total: data?.total,
      });
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (query.get("sprint_id")) {
      setIsModalOpenAddTask(true);
    }
  }, [location?.search]);

  useEffect(() => {
    handleGetAllSprints();
  }, [from, to, isModalOpenAddSprint, isModalOpenAddTask, page]);

  const onRangeChange = (
    dates: null | (Dayjs | null)[],
    dateStrings: string[]
  ) => {
    if (dates) {
      setFrom(dateStrings[0]);
      setTo(dateStrings[1]);
    } else {
      console.log("Clear");
    }
  };
  const onChange: PaginationProps["onChange"] = (pageNumber: number) => {
    setPage(pageNumber);
  };

  return (
    <div className="flex flex-col gap-y-2">
      <Helmet>
        <title>{pageTitle("All Tasks")}</title>
      </Helmet>
      <div className="flex justify-end px-4 gap-x-4">
        <ButtonDefault
          size={4}
          variant={ButtonVariants.PRIMARY}
          className="w-min p-1 shadow-sm rounded-md"
          onClick={() => setIsModalOpenAddSprint(true)}
        >
          <span className="flex items-center text-sm px-2  font-normal gap-x-1  whitespace-nowrap">
            <PlusIcon className="w-4" />
            Add Sprint
          </span>
        </ButtonDefault>
        <RangePicker onChange={onRangeChange} />
      </div>
      {loading ? (
        <div
          className="h-60 flex items-center justify-center"
          style={{ textAlign: "center" }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <div className="flex flex-col gap-y-4 overflow-auto">
          {collapseItems?.map((item, index) => (
            <Collapse
              key={index}
              defaultActiveKey={index === 0 ? [item.key] : undefined}
              className="border-none rounded-none customCollapse"
              items={[item]}
            />
          ))}
        </div>
      )}
      <div className="flex justify-center pt-5">
        <Pagination
          current={paginationData?.current_page}
          total={paginationData?.total}
          pageSize={paginationData?.per_page || 0}
          onChange={onChange}
        />
      </div>

      <AddTaskModal
        isModalOpen={isModalOpenAddTask}
        handleOk={handleOk}
        handleCancel={handleCancelUser}
        sprintId={query.get("sprint_id") || null}
      />
      <AddSprintModal
        isModalOpen={isModalOpenAddSprint}
        handleOk={handleOkSprint}
        handleCancel={handleCancelSprint}
      />
    </div>
  );
};
export default AllTask;
