import { ArrowRightIcon } from "@heroicons/react/outline";
import { Input } from "antd";

import React, { useState } from "react";

import AuthLayout from "../AuthLayout";
import ButtonDefault, { ButtonVariants } from "../../shared/basic/button";
// import { useNavigate } from "react-router-dom";
import { useLogin } from "../../../hooks/useLogin";

import { Logo } from "../../../assets";

const Login = () => {
  const { onLogin, loading } = useLogin();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    // Validation
    const validationErrors = {
      email: "",
      password: "",
    };
    if (!formData.email) {
      validationErrors.email = "Email is required";
    } else if (!isValidEmail(formData.email)) {
      validationErrors.email = "Invalid email";
    }

    if (!formData.password) {
      validationErrors.password = "Password is required";
    }

    setErrors(validationErrors);

    if (Object.values(validationErrors).every((error) => !error)) {
      onLogin(formData.email, formData.password);
    }
  };

  const isValidEmail = (email: string) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  return (
    <AuthLayout>
      <div className="min-h-screen   flex items-center gap-y-10 flex-col justify-center">
        <div className="bg-white min-w-[400px] z-30 px-12 lg:px-8 py-5 xl:py-10 flex flex-col gap-y-1">
          <span className=" flex justify-center ">
            <img src={Logo} alt="Logo" className="object-contain h-7 w-full" />
          </span>
          <h1 className="text-xl font-medium lg:font-normal lg:text-3xl mt-1 leading-10 text-gray-700 text-center w-full">
            Welcome to Zebralearn
          </h1>
          <span className="text-sm lg:text-base text-center flex justify-center">
            Enter your credentials to login
          </span>
          <form onSubmit={handleSubmit} className="flex flex-col  gap-2 mt-2">
            <div className="col-span-1 flex-col flex gap-y-.5">
              <label className="text-base    pb-2">Email</label>
              <Input
                name="email"
                value={formData.email}
                onChange={handleChange}
                size="large"
                className="rounded-none border border-gray-600"
              />
              {errors.email && <p className="text-red-500">{errors.email}</p>}
            </div>
            <div className="col-span-1 flex-col flex gap-y-.5">
              <label className="text-base    pb-2">Password</label>
              <Input.Password
                name="password"
                value={formData.password}
                onChange={handleChange}
                size="large"
                className="rounded-none border border-gray-600"
              />
              {errors.password && (
                <p className="text-red-500">{errors.password}</p>
              )}
              <span
                // onClick={() => router.push("/auth/forgot-password")}
                className="font-medium text-primary mt-1 cursor-pointer hover:text-darkPrimary"
              >
                Forgot password?
              </span>
            </div>

            <span className="flex justify-center mt-4">
              <ButtonDefault
                size={4}
                variant={ButtonVariants.PRIMARY}
                className="w-min p-1"
                loading={loading}
                // onClick={() => router("/marketing")}
              >
                <span className="flex text-xs gap-x-2 px-4 py-1 whitespace-nowrap">
                  SIGN IN <ArrowRightIcon className="w-4 h-4 text-white" />
                </span>
              </ButtonDefault>
            </span>

            <span className="text-center text-sm">
              ©2023 - Zebralearn. All rights reserved
            </span>
          </form>
        </div>
      </div>
    </AuthLayout>
  );
};
export default Login;
