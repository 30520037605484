import { Dictionary, isEmpty } from "lodash";
import { useState } from "react";

import { notify } from "../components/shared/basic/notify";
import { login } from "../utils/lib/auth";

import { useNavigate } from "react-router-dom";
import apiClient from "../services/base";
import { Endpoints } from "../network";
import Cookies from "js-cookie";

export const useLogin = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const router = useNavigate();

  const onLogin = async (
    email: string,
    password: string
  ): Promise<Dictionary<string | JSX.Element>> => {
    setLoading(true);
    const errorMessages: Dictionary<JSX.Element | string> = {};

    let notificationSent = false; // Flag to track if notification has been sent

    try {
      // generic login call
      const response = await apiClient.post(Endpoints.login, {
        email,
        password,
      });

      // extract the data and login the user
      const { data } = response;
      router("/marketing");

      if (isEmpty(data)) {
        throw Error();
      } else {
        login(data?.data?.token);
        Cookies.set("user_id", data?.data?.user_id);

        if (!notificationSent) {
          notify("Successfully Logged IN!", "success");
          notificationSent = true;
        }
      }
    } catch (error) {
      const message = "Something went wrong, please try again.";

      if (!notificationSent) {
        notify(message, "error");
        notificationSent = true;
      }
    } finally {
      setLoading(false);
    }
    return errorMessages;
  };

  return { loading, onLogin };
};
